import { Box } from "@mui/material";
import React from "react";
import TryEsg from "../../../components/Common/TryEsg";
import Footer from "../../../components/footer";

import "../index.scss";
import Banner from "./Banner";
import GreenPlanning from "./GreenPlanning";
import HeteroESG from "./HeteroESG";
import Listing from "./Listing";

function ESGFramework() {
  return (
    <Box className="index-wrapper">
      <Banner />
      <HeteroESG />
      <GreenPlanning />
      <Listing />
      <TryEsg />
      <Footer />
    </Box>
  );
}

export default ESGFramework;
