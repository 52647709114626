import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import techcharge from "../../img/takecharge.png";
function TakeCharge() {
  return (
    <Box className="pink-bg">
      <Box className="kpartners-wrapper">
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Typography variant="h2">
              Take Charge of Your ESG Reporting
            </Typography>
            <Typography variant="subtitle3">
              ESGFIT is a unique business intelligence tool distinctly designed
              to assist companies across all sectors in conducting an impact
              assessment on various ESG (Environmental, Social, and Governance)
              parameters as part of their day-to-day business operations.
            </Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box className="techimg">
              <img src={techcharge} alt="" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default TakeCharge;
