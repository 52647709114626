import React from "react";
import { Box, Typography } from "@mui/material";

import graph2 from "../../../img/graph2.png";

function Graph2() {
  return (
    <Box className="grid-container">
      <Typography variant="h4" className="title-depth">
        Depth of Knowledge Repository - State Laws
      </Typography>
      <Box className="graph-img">
        <img src={graph2} alt="" />
      </Box>
    </Box>
  );
}

export default Graph2;
