import React from "react";
import { Box, SwipeableDrawer, Typography } from "@mui/material";
import { HiDotsVertical } from "react-icons/hi";
import { Link } from "react-router-dom";

function MobileNav() {
  const [open, setOpen] = React.useState(false);
  const [overView, setOverView] = React.useState(false);
  const [solution, setSolution] = React.useState(false);
  const [innovation, setInnovation] = React.useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  return (
    <>
      <Box className="mobile-responsive" onClick={toggleDrawer(true)}>
        <HiDotsVertical />
      </Box>
      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          sx: {
            width: "50%",
            gap: "10px",
            textAlign: "center",
            marginX: "auto",
            display: "flex",
            justifyContent: "center",
          },
        }}
      >
        <Typography variant="body1" onClick={() => {setOverView(true); setOpen(false)}}>
          Overview
        </Typography>
        <Link to="/expertise" onClick={() => setOpen(false)}>
          <Typography variant="body1" onClick={() => toggleDrawer(false)}>
            Expertise
          </Typography>
        </Link>
        <Typography variant="body1" onClick={() => {setSolution(true); setOpen(false)}}>
          Solutions
        </Typography>

        <Link to="/pricing" onClick={() => setOpen(false)}>
          <Typography variant="body1">Pricing</Typography>
        </Link>
        <Typography variant="body1" onClick={() => {setInnovation(true); setOpen(false)}}>
          Innovation
        </Typography>

        <Link to="/contact-us" onClick={() => setOpen(false)}>
          <Typography variant="body1">Contact Us</Typography>
        </Link>
      </SwipeableDrawer>
      <Overview open={overView} close={() => setOverView(false)} />
      <Solutions open={solution} close={() => setSolution(false)} />
      <Innovation open={innovation} close={() => setInnovation(false)} />
    </>
  );
}

export default MobileNav;

const Overview = ({ open, close, closeDrawer }) => {
  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={close}
      onOpen={close}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        sx: {
          width: "50%",
          gap: "10px",
          marginX: "auto",
          padding: "40px",
          display: "flex",
          justifyContent: "center",
        },
      }}
    >
      <Typography onClick={close}>BACK</Typography>
      <Link to="/about" onClick={close}>
        <Typography variant="body1">About ESGFIT.in</Typography>
      </Link>
      <Link to="/people" onClick={close}>
        <Typography variant="body1">People</Typography>
      </Link>
    </SwipeableDrawer>
  );
};

const Solutions = ({ open, close }) => {
  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={close}
      onOpen={close}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        sx: {
          width: "50%",
          gap: "10px",
          marginX: "auto",
          padding: "20px",
          display: "flex",
          justifyContent: "center",
        },
      }}
    >
      <Typography onClick={close}>BACK</Typography>
      <Link to="/esg-framework" onClick={close}>
        <Typography variant="body1">ESG Framework & Platform</Typography>
      </Link>
      <Link to="/statutory-regulatory-compliance" onClick={close}>
        <Typography variant="body1">
          Statutory & Regulatory
          <br />
          Compliance Platform
        </Typography>
      </Link>
    </SwipeableDrawer>
  );
};

const Innovation = ({ open, close }) => {
  const [researchLab, setResearchLab] = React.useState(false);
  return (
    <>
      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={close}
        onOpen={close}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          sx: {
            width: "50%",
            gap: "10px",
            marginX: "auto",
            padding: "20px",
            display: "flex",
            justifyContent: "center",
          },
        }}
      >
        <Typography onClick={close}>BACK</Typography>
        <Link to="/planet-programme" onClick={close}>
          <Typography variant="body1">The Planet people Program</Typography>
        </Link>
        <Link onClick={() => {setResearchLab(true); close()}}>
          <Typography variant="body1">Research Lab</Typography>
        </Link>
        <Link to="/esg-almanac" onClick={close}>
          <Typography variant="body1">ESG Almanac</Typography>
        </Link>
        <Link to="/podcast" onClick={close}>
          <Typography variant="body1">Podcast</Typography>
        </Link>
      </SwipeableDrawer>
      <ResearchLab
        openMenu={researchLab}
        closeMenu={() => setResearchLab(false)}
      />
    </>
  );
};

const ResearchLab = ({ openMenu, closeMenu }) => {
  return (
    <SwipeableDrawer
      anchor="left"
      open={openMenu}
      onClose={closeMenu}
      onOpen={closeMenu}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        sx: {
          width: "50%",
          gap: "10px",
          marginX: "auto",
          padding: "20px",
          display: "flex",
          justifyContent: "center",
        },
      }}
    >
      <Typography onClick={closeMenu}>BACK</Typography>
      <Link to="/research-lab-2022" onClick={closeMenu}>
        <Typography variant="body1">2022</Typography>
      </Link>
      <Link to="/research-lab-2021" onClick={closeMenu}>
        <Typography variant="body1">2021</Typography>
      </Link>
    </SwipeableDrawer>
  );
};
