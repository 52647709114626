import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import globe from "../../img/globe1.png";

function AcrossGlobe() {
  return (
    <Box className="pink-bg">
      <Box className="kpartners-wrapper">
        <Grid container spacing={4}>
          <Grid item xs={12} md={7}>
            <Typography variant="h4">
              ESG across the globe is gaining momentum by the day. It helps
              create transparency and accountability; facilitate top-line growth
              in the long run, and promote institutional investments.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box>
              <Typography variant="h5">ESGFIT Highlights</Typography>
              <Typography variant="subtitle3">
                Monitor Short/Long Term Financial Performance
              </Typography>
              <Typography variant="subtitle3">
                Empower Resources More than Ever
              </Typography>
              <Typography variant="subtitle3">
                Global Performance Standards
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <NumData />
        <Box className="globeimg">
          <img src={globe} alt="" />
        </Box>
      </Box>
    </Box>
  );
}

export default AcrossGlobe;

const NumData = () => {
  return (
    <Grid container marginTop="40px" spacing={3}>
      {data.map((item, index) => (
        <Grid
          item
          key={index}
          xs={6}
          md={2.4}
          sx={{
            textAlign: "center",
          }}
        >
          <Typography variant="h1" color="#C87533">
            {item.title}
          </Typography>
          <Typography variant="subtitle1"> {item.desc} </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

const data = [
  {
    title: "300 +",
    desc: "Data Points for Manufacturing Sector",
  },
  {
    title: "250 +",
    desc: "Data Points for Services Sector",
  },
  {
    title: "100 +",
    desc: "Actionables Dealt on As-Is Basis",
  },
  {
    title: "1,000 +",
    desc: "State & Central Laws Covered across Jurisdictions",
  },
  {
    title: "100 +",
    desc: "Languages in Offering",
  },
];
