import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { FaCheckCircle } from "react-icons/fa";

function KSGComp() {
  return (
    <Box className="ksg-comp-wrapper">
      <Typography variant="h1" className="title" data-aos="bounce-around">
        Key ESG Components
      </Typography>
      {data.map((item, index) => (
        <Box key={index} className="circle-wrapper" data-aos="zoom-in">
          <Box className="circle1">
            <FaCheckCircle />
            <Typography variant="h5">{item?.title1}</Typography>
            <Divider className="divider-1" />
            <Divider className="divider-2" />
          </Box>
          <Box className="circle1 circle2">
            <FaCheckCircle />
            <Typography variant="h5">{item?.title2}</Typography>
            <Divider className="divider-1" />
            <Divider className="divider-2" />
          </Box>
          {item?.title3 && (
            <Box className="circle1 circle3">
              <FaCheckCircle />
              <Typography variant="h5">{item?.title3}</Typography>
              <Divider className="divider-1" />
              <Divider className="divider-2" />
            </Box>
          )}
          {item?.title4 && (
            <Box className="circle1 circle4">
              <FaCheckCircle />
              <Typography variant="h5">{item?.title4}</Typography>
              <Divider className="divider-1" />
              <Divider className="divider-2" />
            </Box>
          )}
        </Box>
      ))}
      <Typography variant="h5" className="title">
        “It takes 20 years to build a reputation and five minutes to ruin it.”
      </Typography>
      <Typography variant="h6" className="benjamin">
        Benjamin Franklin
      </Typography>
    </Box>
  );
}

export default KSGComp;

const data = [
  {
    title1: "Sustainable Finance",
    title2: "Capital Markets",
    title3: "Business & Human Rights",
    title4: "Sustainable Workforce & Labour Issues",
  },
  {
    title1: "Ownership of Natural Assets",
    title2: "Corporate Responsibility",
    title3: "Governance",
  },
  {
    title1: "Environmental Liability",
    title2: "Climate Change & Carbon Emissions",
  },
];
