import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import openingremark from "../../../img/openingremark.jpg";
import panel from "../../../img/panel.jpg";

function Panel() {
  return (
    <Box className="grey-bg-graph1">
      <Box className="grid-container">
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <Box className="graph-img">
              <img src={openingremark} alt="" />
            </Box>
            <Typography variant="h3">Opening Remarks</Typography>
            <li>
              Alok Dhir, Founder & Managing Partner, Dhir & Dhir Associates
            </li>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className="graph-img">
              <img src={panel} alt="" />
            </Box>
            <Typography variant="h3">
              Panel Discussion amongst Industry Experts
            </Typography>
            <li>
              1st LIVE Deep Down into India & Global Versions of The ESG Almanac
              & its Actionables.
            </li>
            <li>
              Alok Dhir, Founder & Managing Partner, Dhir & Dhir Associates
            </li>
            <li>ESG Culture Building in Companies.</li>
            <li>Bringing together ESG Stakeholders & the Last Mile Run.</li>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Panel;
