import React from "react";
import { Box, Typography } from "@mui/material";

function Banner() {
  return (
    <Box className="almanac-banner">
      <Typography variant="h3" color="white">
        Mastering The ESG Journey – Environmental, Social & Governance
      </Typography>
      <br />
      <Typography>
        UNVEILING OF THE ESG ALMANAC – INDIA & GLOBAL A FIRST OF ITS KIND
        <br />
        PROPRIETARY TOOL BY DHIR & DHIR ASSOCIATES
      </Typography>
    </Box>
  );
}

export default Banner;
