import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { AiOutlineRight } from "react-icons/ai";

function MenuList() {
  return (
    <Box className="dropdowns">
      <Overview />
      <Link to="/expertise" className="menutitle">
        Expertise
      </Link>
      <Solutions />
      <Innovations />
      <Link to="/pricing">Pricing</Link>
      <Link to="/contact-us">Contact Us</Link>
    </Box>
  );
}

export default MenuList;

const Overview = () => {
  const [anchorEl, setAnchorEl] = React.useState(false);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(false);
  };
  return (
    <>
      <Link
        to="#"
        className="menutitle"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(event) => setAnchorEl(event.target)}
      >
        OverView <MdOutlineKeyboardArrowDown />
      </Link>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        elevation={1}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <Link to="/about">About ESGFIT.in</Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to="/people"> People</Link>
        </MenuItem>
      </Menu>
    </>
  );
};

const Solutions = () => {
  const [anchorEl, setAnchorEl] = React.useState(false);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(false);
  };
  return (
    <>
      <Link
        to="#"
        className="menutitle"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(event) => setAnchorEl(event.target)}
      >
        Solutions
        <MdOutlineKeyboardArrowDown />
      </Link>
      <Menu
        id="menu1"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <Link to="/esg-framework">ESG Framework & Platform</Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to="/statutory-regulatory-compliance">
            Statutory & Regulatory
            <br />
            Compliance Platform
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
};

const Innovations = () => {
  const [anchorEl, setAnchorEl] = React.useState(false);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(false);
  };

  return (
    <>
      <Link
        to="#"
        className="menutitle"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(event) => setAnchorEl(event.target)}
      >
        Innovations
        <MdOutlineKeyboardArrowDown />
      </Link>
      <Menu
        id="menu1"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <Link to="/planet-programme">The Planet people Program</Link>
        </MenuItem>
        <MenuItem>
          <ResearchLabMenu />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to="/esg-almanac">ESG Almanac</Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to="/podcast">Podcast</Link>
        </MenuItem>
      </Menu>
    </>
  );
};

const ResearchLabMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(false);
  };
  const navigate = useNavigate();

  return (
    <>
      <div className="menutitle">
        <Box display="flex" justifyContent="center" alignItems="center">
          <Link
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(event) => setAnchorEl(event.target)}
          >
            Research Lab
          </Link>
          <AiOutlineRight />
        </Box>
      </div>
      <Menu
        id="menu1"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(false);
            navigate("/research-lab-2022");
          }}
        >
          2022
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(false);
            navigate("/research-lab-2021");
          }}
        >
          2021
        </MenuItem>
      </Menu>
    </>
  );
};
