import { Box } from "@mui/material";
import React from "react";
import Footer from "../../../components/footer";

import "../index.scss";
import Banner from "./Banner";
import Concept from "./Concept";
import Patrons from "./Patrons";
import TheHonor from "./TheHonor";
import TheProgram from "./TheProgram";
import TheRules from "./TheRules";

function PlanetProgram() {
  return (
    <Box className="index-wrapper">
      <Banner />
      <Concept />
      <TheProgram />
      <Patrons />
      <TheRules />
      <TheHonor />
      <Footer />
    </Box>
  );
}

export default PlanetProgram;
