import { Box } from "@mui/material";
import React from "react";
import Footer from "../../../components/footer";
import "../index.scss";
import Banner from "./Banner";
import Graph1 from "./Graph1";
import Graph2 from "./Graph2";
import IndudtrySpecific from "./IndudtrySpecific";

function ESGCompliance() {
  return (
    <Box className="index-wrapper">
      <Banner />
      <Graph1 />
      <Graph2 />
      <IndudtrySpecific />
      <Footer />
    </Box>
  );
}

export default ESGCompliance;
