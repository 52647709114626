import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import bag from "../../img/bag.png";
import medal from "../../img/medal.png";
import blockchain from "../../img/blockchain.png";

function ServiceAnthem() {
  return (
    <Box className="service-anthem-section">
      <Titlesection />
      <CardSection />
    </Box>
  );
}

export default ServiceAnthem;

const Titlesection = () => {
  return (
    <Box className="title-section">
      <Typography variant="body2" className="anthem-text">
        Our Service Anthem
      </Typography>
      <Typography variant="h2">
        We Handhold <br /> Our Clients towards <br /> ESG Consciousness
      </Typography>
    </Box>
  );
};

const CardSection = () => {
  return (
    <Box className="card-section-service">
      <Box className="card-wrapper-service">
        <Box className="card1">
          <div data-aos="fade-up">
            <Typography variant="h3">
              Why Choose <br /> ESGFIT?
            </Typography>
            <Box className="zigzag">
              <div className="zig-zag-bottom" />
            </Box>
            <Typography variant="subtitle1">
              A unique business intelligence tool, distinctly designed to assist
              companies across all sectors to conduct an impact assessment on
              various ESG (Environmental, Social and Governance) parameters as
              part of their day-to-day business operations.
            </Typography>
          </div>
        </Box>
        {data.map((item, index) => (
          <Box key={index} className="card2">
            <img src={item.src} alt="" data-aos="zoom-in" />
            <br />
            <div data-aos="fade-up">
              <Typography variant="h5">{item.title}</Typography>
              <Box className="zigzag">
                <div className="zig-zag-bottom" />
              </Box>
              <Typography variant="subtitle1">{item.desc}</Typography>
            </div>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const data = [
  {
    src: bag,
    title: "Cloud Adaptability and Security",
    desc: "ESGFIT is a SAAS based certified model with a secure local cloud provider of high repute.",
  },
  {
    src: medal,
    title: "ESG & BRSR Canvas",
    desc: "Automated across different performance standards globally – first of its kind Heat Map Generator",
  },
  {
    src: blockchain,
    title: "Blockchain Powered",
    desc: "Enabled on Ethereum derivatives, which enhances security, transparency, and tractability of data.",
  },
];
