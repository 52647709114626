import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import loader from "../../img/loader-colored.gif";
import app1 from "../../img/app/app1.png";
import app2 from "../../img/app/app2.png";
import app3 from "../../img/app/app3.png";
import app4 from "../../img/app/app4.png";
import { Link } from "react-router-dom";
// src\img\

function Banner() {
  return (
    <Box className="grid-container">
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box className="colored-loader">
            <img src={loader} alt="" />
          </Box>
          <Typography variant="subtitle3" className="title">
            Our objective is to provide a one-stop solution for all ESG related
            matters that may impact a company's financial metrics and
            potentially affect investment decisions. We go beyond spreadsheets
            and help companies benchmark industry best practices while aligning
            with global performance standards focused on ESG specific
            disclosures and reporting.
          </Typography>
          <Typography variant="h2">Our Mission</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Box className="app-1">
            <img src={app1} alt="" />
          </Box>
          <Box className="app-2">
            <img src={app2} alt="" />
          </Box>
          <Box className="app-3">
            <img src={app3} alt="" />
          </Box>
          <Box className="app-4">
            <img src={app4} alt="" />
          </Box>
          <Typography variant="h3" className="title" style={{marginTop:"40px"}}>
            Here’s our much-in-
            <br />
            demand ESG Handbook
            <br />
            on Applicable Laws & Regulations.
          </Typography>
          <Link
            to="/contact-us"
            className="common-button-opposite button-width"
          >
            I'm Interested
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Banner;
