import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import concept from "../../../img/concept.jpeg";

function Concept() {
  return (
    <Box className="grid-container">
      <Typography variant="h4" className="title">
        THE CONCEPT
      </Typography>
      <Grid container spacing={10}>
        <Grid item md={6}>
          <Box className="graph-img">
            <img src={concept} alt="" />
          </Box>
        </Grid>
        <Grid item md={6}>
          <Typography marginTop="30px">
            The adverse effects of human activities on the planet are no secret.
            The consequence of our exploitation is evidently seen in the drastic
            rise in temperature levels leading to the melting of glaciers,
            forest fires, etc. From land to water to air, the overutilization of
            environment resources has created an existential threat to all
            living beings. Therefore, urgent, and collaborative action must be
            undertaken to successfully achieve the transformational journey for
            the planet toward clean, green, and sustainable development.
          </Typography>
          <Typography marginTop="30px">
            The Planet People Program (PPP) Charter outlines our vision,
            objectives, management, and governance controls to promote the
            efficient integration of Environmental, Social, and Governance (ESG)
            principles into our day-to-day activities and initiatives. Our
            purpose is to facilitate a platform for the youth to make the right
            impact, one that is positive on the environment and community.
            Furthermore, we aim to endorse the need for adherence to ESG
            principles as an integral part of our journey toward sustainability.
            This PPP Charter guides the following generation of lawyers on
            matters about ESG aspects and helps build a community that is
            conscious in its conduct and collectively fights the crisis faced by
            the planet and people today.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Concept;
