import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import welcome2 from "../../../../img/welcome2.jpeg";

function ExpertsView() {
  return (
    <Box className="grey-bg-graph1">
      <Box className="grid-container">
        <Typography variant="h4" className="title">
          EXPERTS’ VIEWS
        </Typography>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box className="graph-img">
              <img src={welcome2} alt="" />
            </Box>
            <br />
            <Typography variant="h3">
              Welcome Remarks and Keynote Address
            </Typography>
            <br />
            <li className="font-circular-md">
              Welcome Remarks by Alok Dhir, Founding & Managing Partner, Dhir &
              Dhir Associates.
            </li>
            <li className="font-circular-md">
              Keynote Address by Cherie Blair CBE, QC, Co-Founder, and Chair,
              Omnia Strategy LLP.
            </li>
          </Grid>
          <Grid item xs={12} md={6}>
            <iframe
              width="100%"
              height="280"
              src="https://www.youtube.com/embed/iI3lbxswu74"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
            <br />
            <Typography variant="h4">
              Panel Discussion with Industry Experts
            </Typography>
            <br />
            <Typography variant="body3">Key Discussion Points</Typography>
            <br />
            {list.map((item, index) => (
              <Box key={index}>
                <li className="font-circular-md">{item.title} </li>
              </Box>
            ))}
            <br />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ExpertsView;

const list = [
  {
    title: "Environment Day & Ecosystem Restoration.",
  },
  {
    title: "Growing Importance of ESG for Indian Companies.",
  },
  {
    title: "Business & Human Rights.",
  },
  {
    title:
      "Case Studies of How Companies Can Use ESG Principles for Value Creation in their Organisation.",
  },
  {
    title: "ESG Disputes & ADR: Future Roadmap.",
  },
];
