import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import legal from "../../../../img/legal.jpeg";

function YetAgain() {
  return (
    <Box className="grid-container">
      <Grid container spacing={10}>
        <Grid item xs={12} md={6}>
          <Box className="graph-img">
            <img src={legal} alt="" />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h3">Yet Again</Typography>
          <br />
          <Typography variant="subtitle5">
            Dhir & Dhir Associates conducted the 2nd Edition of India’s First
            Virtual Legal Marathon – A 24 hours Research Lab on ESG
            (Environment, Social & Governance), which was flagged off with an
            inaugural ceremony on 3rd June, 2022 under the patronage of renowned
            industry experts and environmentalists and was successfully
            concluded on 5th June, 2022 commemorating World Environment Day.
            <br />
            <br />
            This year the response was a staggering count of 700+ Registrations
            from around 100 Educational establishments. About 100 Students were
            shortlisted for the 24 Hour Marathon, and 30 Winners were
            acknowledged for their research submissions during the Marathon,
            given widespread recognition and they were felicitated with
            certificate of achievement. The initiative was registered as{" "}
            <span className="text-green">
              #OnlyOneEarth Action for 2022 for Celebrating 2022 World
              Environment Day,
            </span>{" "}
            led by the United Nations Environment Programme (UNEP).
            <br />
            <br /> The 30 Winners have been offered internship positions at Dhir
            & Dhir Associates. In addition, a few winners would be placed as
            interns with Re Sustainability Limited, one of Asia’s leading
            providers of comprehensive environment management services, as part
            of corporate participation in the ESG research Lab. Conclusively,
            the winners will receive an annual subscription to Lex Witness –
            India’s 1st Magazine on Legal & Corporate Affairs and a
            Co-Authorship Opportunity with the ESG Team Members at Dhir & Dhir
            Associates.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default YetAgain;
