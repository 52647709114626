import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";

function SocialMedia() {
  return (
    <div className="follow-us">
      <span>
        <FaFacebookF />
      </span>
      <span>
        <BsTwitter />
      </span>
      <span>
        <BsLinkedin />
      </span>
      <span>
        <BsInstagram />
      </span>
    </div>
  );
}

export default SocialMedia;
