import React from "react";
import { Box, Typography } from "@mui/material";
import Winners2 from "../../../../img/Winners2.jpeg";

function Winners() {
  return (
    <Box className="grid-container">
      <Typography variant="h4" className="title">
        Wall of Fame – The Winners
      </Typography>
      <Typography variant="body4">
        Congratulations to all the Winners for their active participation and
        successful completion of the 2nd Edition of India’s First Virtual Legal
        Marathon on ESG – A 24 Hour Research Lab. The initiative gained
        widespread popularity, especially among youth, whose energies and
        diligence are now channelized towards the development of self, society,
        and nation.
      </Typography>
      <br /> <br />
      <Box className="graph-img">
        <img src={Winners2} alt="" />
      </Box>
    </Box>
  );
}

export default Winners;
