import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Googlemap from "../../img/googlemap.png";
import SocialMedia from "../Common/SocialMedia";
import Gpodcast from "../../img/Gpodcast.png";
import spotify from "../../img/spotify.png";
import logo from "../../img/logo.png";
import "./index.scss";

function Footer() {
  return (
    <>
      <div className="logo-section">
        <img src={logo} alt="" />
      </div>
      <Box className="footer-main-wrapper">
        <div className="divider" />
        <Box className="footer-wrapper">
          <Box className="footer-link">
            <div className="google-map">
              <img src={Googlemap} alt="/" />
            </div>
            <Typography variant="body1">
              D 55, Defence Colony,
              <br /> New Delhi-110024 <br />
              Tel: 91 (11) 42410000 <br />
              Fax: 91 (11) 42410091 <br />
              E: yes@esgfit.in
            </Typography>
          </Box>
          <Box className="footer-link">
            <div className="google-map">
              <img src={Googlemap} alt="/" />
            </div>
            <Typography variant="body1">
              # 21 & 22, 3rd Floor, <br /> Onlooker Building,
              <br /> Sir P.M. Road, Fort,
              <br />
              Mumbai – 400001, India <br />
              Tel: +91 (22) 67472284
            </Typography>
          </Box>
          <Box className="footer-link">
            <Typography variant="body2">Company</Typography>
            {data1.map((item, index) => (
              <Link key={index} to={item.link}>
                <Typography variant="body1">{item.title}</Typography>
              </Link>
            ))}
          </Box>
          <Box className="footer-link">
            <Typography variant="body2">Platform</Typography>
            {data2.map((item, index) => (
              <Link key={index} to={item.link}>
                <Typography variant="body1">{item.title}</Typography>
              </Link>
            ))}
          </Box>
          <Box className="footer-link">
            <Typography variant="body2">Innovation</Typography>
            {data3.map((item, index) => (
              <Link key={index} to={item.link}>
                <Typography variant="body1">{item.title}</Typography>
              </Link>
            ))}
          </Box>
          <Box className="footer-link">
            <Typography variant="body2">Get ESG Ready</Typography>
            {data4.map((item, index) => (
              <Link key={index} to={item.link}>
                <Typography variant="body1">{item.title}</Typography>
              </Link>
            ))}
          </Box>
        </Box>
        <Box className="footer-wrapper">
          <div>
            <Typography variant="body1" className="text-grey">
              © Copyright 2022
            </Typography>
            <SocialMedia />
          </div>
          <div className="img-wrapper">
            <div>
              <a
                href="https://open.spotify.com/show/7axvFunKU1xVN24sg1bqBC"
                target="_blank"
              >
                <img src={spotify} alt="" />
              </a>{" "}
            </div>
            <div>
              <a
                href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy83NTdiZTdhNC9wb2RjYXN0L3Jzcw"
                target="_blank"
              >
                <img src={Gpodcast} alt="" />
              </a>{" "}
            </div>
          </div>
        </Box>
        <div className="overlay"></div>
      </Box>
    </>
  );
}

export default Footer;

const data1 = [
  { title: "About Us", link: "/about" },
  { title: "Expertise", link: "/expertise" },
  { title: "People", link: "/people" },
  { title: "Contact Us", link: "/contact-us" },
  { title: "Legal", link: "/legal" },
];
const data2 = [
  { title: "ESG Framework", link: "/esg-framework" },
  { title: "Statutory & Regulatory", link: "/statutory-regulatory-compliance" },
  { title: "Compliance", link: "/statutory-regulatory-compliance" },
];
const data3 = [
  { title: "PPP 2022", link: "/" },
  { title: "Research Lab", link: "/" },
  { title: "ESG Almanac", link: "/esg-almanac" },
  { title: "Podcasts", link: "/podcast" },
];
const data4 = [
  { title: "Book Your ESG Session", link: "/contact-us" },
  { title: "Pricing", link: "/pricing" },
];
