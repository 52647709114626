import React from "react";
import { Box, Typography } from "@mui/material";

import tryesg from "../../img/tryesg.jpeg";
import { FaArrowAltCircleDown } from "react-icons/fa";
import { Link } from "react-router-dom";
function TryEsg() {
  return (
    <>
      <Box className="try-esg-wrapper">
        <Box className="text-section" data-aos="zoom-out" data-aos-delay="500">
          <Typography variant="h2" color="#FFF">
            Try ESGFIT
          </Typography>
          <Typography variant="body1">
            We’d be glad to work with you.
          </Typography>
          <Link to="/contact-us">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
            >
              <FaArrowAltCircleDown />
              <Typography variant="h5" color="white">
                Book Your ESG Session
              </Typography>
            </Box>
          </Link>
        </Box>
        <img src={tryesg} alt="" data-aos="zoom-in"/>
      </Box>
    </>
  );
}

export default TryEsg;
