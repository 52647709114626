import { Box, Grid, Typography } from "@mui/material";
import React from "react";

function Background() {
  return (
    <Box className="grid-container">
      <Grid container spacing={10}>
        <Grid item xs={12} md={6} display="flex" alignItems="center">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/DQkFSzKf0ow"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4">THE BACKGROUND</Typography>
          <br />
          <Typography variant="subtitle5">
            Years of exploitation of resources has brought the world to the
            brink of disaster and ESG stands as a sentinel to guard against any
            further exploitation of our environment, human resources and to put
            sustainable governance standards in place.
            <br />
            <br />
            As a way to promote and contribute towards corporate ESG alignment,
            Dhir & Dhir Associates released the Indian and Global versions of
            the ESG Almanac. It is a first of its kind proprietary tool
            developed by the firm and constitutes a set of sample framework for
            businesses to interpret and ascertain a definite annual agenda in
            line with the ESG journey.
            <br />
            <br />
            One of the major USPs of the Almanacs is its emphasis on the
            importance of having a designated framework for all businesses
            irrespective of the type of industry or sector. Each month has a
            different set of actionables, which can be further narrowed down and
            customized as per relevant company requirements.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Background;
