import React from "react";
import "./index.scss";
import Banner from "./Banner";
import OurPeople from "./OurPeople";
import { Box } from "@mui/material";
import Footer from "../../components/footer";

function People() {
  return (
    <Box className="index-wrapper">
      <Banner />
      <OurPeople />
      <Footer />
    </Box>
  );
}

export default People;
