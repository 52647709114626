import React from "react";
import { Box, Grid, Typography } from "@mui/material";

function TheRules() {
  return (
    <Box className="grey-bg-graph1">
      <Box className="grid-container text-align-center">
        <Typography variant="h4" className="title">
          THE RULES
        </Typography>
        <Typography>
          Great leadership sets the right direction, creates a clear vision,
          helps align with people, inspires strong company culture & overall
          objectives, and motivates the stakeholders for enhanced efforts toward
          sustainable development. Dhir & Dhir Associates as the core patron,
          helped create a council of patron colleges and their students, who
          were selected based on specific criteria decided by the core patron.
          The core patron and other participating colleges will have periodic
          meetings to drive suggestions and working of the PPP Council.
        </Typography>
        <br />
        <Typography fontStyle="italic">
          Each patron college nominated one participant each per category basis
          the following governing structure.
        </Typography>
        <br />
        <StructureData />
      </Box>
    </Box>
  );
}

export default TheRules;

const StructureData = () => {
  return (
    <Grid container spacing={3}>
      {data.map((item, index) => (
        <Grid item md={2.4} xs={6}>
          <Box margin="20px">
            <Typography variant="h6">{item.title} </Typography>
            <Typography className="text-orange">{item.subtitle} </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

const data = [
  {
    title: "5TH YEAR/3RD YEAR (3 YEAR LLB COURSE)",
    subtitle: "CHIEF AMBASSADOR",
  },
  {
    title: "4TH YEAR/2ND YEAR (3 YEAR LLB COURSE)",
    subtitle: "TEAM AMBASSADOR",
  },
  {
    title: "3RD YEAR/1ST YEAR (3 YEAR LLB COURSE)",
    subtitle: "TEAM AMBASSADOR",
  },
  {
    title: "TEAM AMBASSADOR",
    subtitle: "TEAM AMBASSADOR",
  },
  {
    title: "1ST YEAR",
    subtitle: "TEAM AMBASSADOR",
  },
];
