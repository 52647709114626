import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AppBar, Box, Modal, Toolbar, Typography } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import logo from "../../img/logo.png";
import MenuList from "./MenuList";
import MobileNav from "./MobileNav";
import "./index.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

function Navbar() {
  const [open, setOpen] = useState(false);
  return (
    <AppBar
      component="nav"
      elevation={0}
      style={{
        background: "#FFFFFF",
        boxShadow: "0 1px 20px rgb(0 0 0  / 0.1)",
      }}
    >
      <Toolbar variant="dense" className="Navbar">
        <Link to="/">
          <img src={logo} alt="" className="logo" />
        </Link>
        <MenuList />
        <Box className="button-nav">
          <Link onClick={() => setOpen(true)}>
            <button className="common-button signup">Login</button>
          </Link>
          <div className="search-div">
            <FaSearch />
          </div>
          <MobileNav />
        </Box>
      </Toolbar>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Please Select Platform To Login
          </Typography>
          <button
            className="common-button signup"
            style={{ margin: "25px 0" }}
            onClick={() => setOpen(false)}
          >
            <a href="https://compliance.esgfit.in" target="_blank">
              Statutory & Regulatory Compliance Platform
            </a>
          </button>
          <button
            className="common-button signup"
            onClick={() => setOpen(false)}
          >
            {" "}
            <a href="https://esg-framework.esgfit.in" target="_blank">
              ESG Framework Platform
            </a>
          </button>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Don't have an account?{" "}
            <Link
              to="/contact-us"
              onClick={() => setOpen(false)}
              style={{ color: "#3bac75" }}
            >
              Contact Us
            </Link>
          </Typography>
        </Box>
      </Modal>
    </AppBar>
  );
}
export default Navbar;
