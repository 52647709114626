import React from "react";
import { Box, Typography } from "@mui/material";

function Banner() {
  return (
    <Box className="podcast-banner rl1-banner">
      <Box className="grid-container">
        <Typography variant="h4" color="white">
          India’s 1st Virtual Legal Marathon on ESG
        </Typography>
        <Typography>A 24 HOUR RESEARCH LAB</Typography>
        <Typography>
          A REGISTERED UNEP EVENT FOR WORLD ENVIRONMENT DAY CELEBRATIONS 2021
        </Typography>
      </Box>
    </Box>
  );
}

export default Banner;
