import { Box } from "@mui/material";
import React from "react";
import Footer from "../../components/footer";
import AcrossGlobe from "./AcrossGlobe";
import Banner from "./Banner";
import "./index.scss";
import KPartners from "./KPartners";
import TakeCharge from "./TakeCharge";

function About() {
  return (
    <Box className="index-wrapper">
      <Banner />
      <KPartners />
      <TakeCharge />
      <AcrossGlobe />
      <Footer />
    </Box>
  );
}

export default About;
