import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import speakers from "../../../../img/speakers.jpeg";

function ExpertsView() {
  return (
    <Box className="grey-bg-graph1">
      <Box className="grid-container">
        <Typography variant="h4" className="title">
          EXPERTS’ VIEWS
        </Typography>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box className="graph-img">
              <img src={speakers} alt="" />
            </Box>
            <br />
            <Typography variant="h3">Welcome Address</Typography>
            <br />
            <Typography variant="body3">
              Alok Dhir, Founder & Managing Partner,
              <span className="font-circular-md"> Dhir & Dhir Associates </span>
            </Typography>
            <br />
            <p>Inaugural Address by Guest of Honour</p>
            <Typography variant="body4">
              Hon’ble Justice (Retd.) Dipak Misra,
              <span className="font-circular-md">
                {" "}
                Former Chief Justice of India
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <iframe
              width="100%"
              height="280"
              src="https://www.youtube.com/embed/iI3lbxswu74"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
            <br />
            <Typography variant="h4">
              Panel Discussion with Industry Experts
            </Typography>
            <br />
            {data.map((item, index) => (
              <Box key={index}>
                <Typography variant="body3">
                  {item.title},
                  <span className="font-circular-md"> {item.desc}</span>
                </Typography>
              </Box>
            ))}
            <br />
            <Typography variant="body3">
              Key Discussion Points – Impact of ESG & #OnlyOneEarth
            </Typography>
            <br />
            <br />
            {list.map((item, index) => (
              <Box key={index}>
                <li className="font-circular-md">{item.title} </li>
              </Box>
            ))}
            <br />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ExpertsView;

const data = [
  {
    title: "Manish Gupta",
    desc: "Group General Counsel, CK Birla Group",
  },
  {
    title: "Prateek Sharma",
    desc: " Actor & Environmentalist",
  },
  {
    title: "Tanishaa Mukerji",
    desc: "Independent Director, GE (General Electric) Power India Ltd., India Glycols Ltd. & Others",
  },
  {
    title: "Shukla Wassan",
    desc: "Group General Counsel, CK Birla Group",
  },
  {
    title: "Sonal Verma",
    desc: "Partner – ESG, Dhir & Dhir Associates",
  },
];

const list = [
  {
    title: "Impact of ESG & #OnlyOneEarth",
  },
  {
    title: "Role of ESG & Sustainability Practices on #OnlyOneEarth",
  },
  {
    title: "People – the most important player in protecting #OnlyOneEarth",
  },
  {
    title: "Environment Restoration – A Dire Need",
  },
  {
    title:
      "Relevance of BRSR to be #OnlyOneEarth Crusader for more responsible Non-Financial Disclosures",
  },
  {
    title: "Case Studies",
  },
];
