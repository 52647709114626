import React from "react";
import { Box, Typography } from "@mui/material";
import Platform from "../../img/Platformimg.png";
import CommonFAQ from "../Common/CommonFAQ";
import CommonDivider from "../Common/CommonDivider";

function ExploreSection() {
  return (
    <Box className="explore-wrapper">
      <Typography variant="h1" className="text-black">
        Explore Our
        <br /> State of the Art Solutions
      </Typography>
      <br />
      <Typography variant="body2" className="text-black">
        Customised as per Your ESG Needs
      </Typography>
      <Box className="faq-img">
        <Box className="img-box">
          <img src={Platform} alt="" data-aos="zoom-in" data-aos-delay="800" />
        </Box>
        <Box className="faq-box">
          <Typography variant="h1">ESG Framework Platform</Typography>
          <br />
          <CommonFAQ data={data1} />
        </Box>
      </Box>
      <br />
      <Box className="faq-img faq-img-second">
        <Box className="img-box">
          <img src={Platform} alt="" data-aos="zoom-in" data-aos-delay="800" />
        </Box>
        <Box className="faq-box">
          <Typography variant="h1">
            Statutory & Regulatory Compliance Platform
          </Typography>
          <br />
          <CommonFAQ data={data2} />
        </Box>
      </Box>
      <br />
      <br />
      <br />
      <CommonDivider />
    </Box>
  );
}

export default ExploreSection;

const data1 = [
  {
    title: "Handles ESG Heterogeneity",
    desc: "Easily configures the challenges of Heterogeneity in ESG Frameworks following a centralized approach to manage your ESG requirements.",
  },
  {
    title: "Avoid Greenwashing",
    desc: "Creates trails & evidence to safeguard via periodic reporting & audits. ESG greenwashing is in disguise cheating upon your stakeholders.",
  },
  {
    title: "Visual Business Intelligence",
    desc: "Heat Maps on ESG elements gives the management tangible decision-making information e.g., Resource allocation, Capex, OPEX etc.",
  },
];

const data2 = [
  {
    title: "DIY (Do It Yourself) & DIFM (Do It for Me) Methodology",
    desc: "Managed services on compliance facilitated by us with Tech controls in your hand powered by advanced tech features of Web 3.0 & evolving further.",
  },
  {
    title: "Satisfaction Guaranteed",
    desc: "CRM, Training Center & Forms digitisation Center makes the platform valuable across 365 days and just not a repository and/or reporting tool.",
  },
  {
    title: "Relevant Content",
    desc: "No more unnecessary tasks, missed tasks or pending tasks. Find logical, structured compliance content for reporting.",
  },
];
