import React from "react";
import { Box, Divider } from "@mui/material";

function CommonDivider() {
  return (
    <Box className="divider-wrapper">
      <Divider className="vertical-divider" />
    </Box>
  );
}

export default CommonDivider;
