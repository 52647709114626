import React from "react";
import { Box } from "@mui/material";

import "./index.scss";
import HomeBanner from "./HomeBanner";
import ServiceAnthem from "./ServiceAnthem";
import OurVision from "./OurVision";
import Lumen from "./Lumen";
import ExploreSection from "./ExploreSection";
import Cards from "./Cards";
import Pricingbanner from "../Pricing/Pricingbanner";
import TryEsg from "../Common/TryEsg";
import Footer from "../footer";

function Home() {
  return (
    <Box className="home-wrapper">
      <HomeBanner />
      <ServiceAnthem />
      <OurVision />
      <Lumen />
      <ExploreSection />
      <Cards />
      <Box className="pricing-main-wrapper" marginTop="50px">
        <Pricingbanner />
      </Box>
      <TryEsg />
      <Footer />
    </Box>
  );
}

export default Home;
