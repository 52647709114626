import React from "react";
import { Box, Typography } from "@mui/material";

import launchevent from "../../../img/luanchevent.jpg";

function LaunchCeremony() {
  return (
    <Box className="grid-container">
      <Typography variant="h4" className="title">
        EXPERTS’ VIEWS AT THE ALMANAC LAUNCH CEREMONY
      </Typography>
      <Box className="graph-img">
        <img src={launchevent} alt="" />
      </Box>
    </Box>
  );
}

export default LaunchCeremony;
