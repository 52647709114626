import React from "react";
import "../index.scss";
import Banner from "./Banner";
import Background from "./Background";
import GlobalIndia from "./GlobalIndia";
import LaunchCeremony from "./LaunchCeremony";
import Panel from "./Panel";
import StayTuned from "./StayTuned";
import TryEsg from "../../../components/Common/TryEsg";
import { Box } from "@mui/material";
import Footer from "../../../components/footer";

function Almanac() {
  return (
    <Box className="index-wrapper">
      <Banner />
      <Background />
      <GlobalIndia />
      <LaunchCeremony />
      <Panel />
      <StayTuned />
      <TryEsg />
      <Footer />
    </Box>
  );
}

export default Almanac;
