import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { FiChevronDown as ExpandMoreIcon } from "react-icons/fi";

function CommonFAQ({ data = [] }) {
  const [expanded, setExpanded] = React.useState(0);
  return (
    <Box className="Accordian-wrapper">
      {data.map((item, index) => (
        <Accordion
          expanded={expanded === index}
          onChange={(_, isExpanded) => setExpanded(isExpanded ? index : false)}
          onClick={() => {
            setExpanded(expanded == index? -1: index);
          }}
          key={index}
          className="Accordian-solo"
          disableGutters
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={expanded === index ? "header-active" : "header"}
          >
            <Typography
              variant="body2"
              style={{
                color: expanded === index ? "#FFF" : "#000",
              }}
            >
              {item?.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" className="Desc">
              {item?.desc}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}

export default CommonFAQ;
