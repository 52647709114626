import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import notepad from "../../../../img/Stats/notepad.png";
import register from "../../../../img/Stats/register.png";
import startrophy from "../../../../img/Stats/startrophy.png";

function Statistics() {
  return (
    <Box className="stat-bg-wrapper">
      <Box className="grid-container">
        <Typography variant="h4" className="title" color="white">
          KEY MARATHON STATISTICS
        </Typography>
        <Grid container spacing={2} border="1px solid white" padding={2}>
          {data.map((item, index) => (
            <Grid item xs={6} md={4}>
              <Box className="stat-details" sx={{ paddingY: 10 }}>
                <img src={item.src} alt="" />
                <Typography variant="h5">{item.title} </Typography>
                <Typography>{item.desc} </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default Statistics;

const data = [
  {
    src: register,
    title: "OVER 450 REGISTRATION",
    desc: "Requests Received",
  },
  {
    src: notepad,
    title: "OVER 100 SHORTLISTED",
    desc: "Contenders",
  },
  {
    src: startrophy,
    title: "30 INTERNSHIPS",
    desc: "Offers to Winners",
  },
];
