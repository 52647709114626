import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import India from "../../../img/IndiaEsg.jpg";
import Global from "../../../img/GlobalEsg.jpg";
import { Link } from "react-router-dom";

function GlobalIndia() {
  return (
    <Box className="grey-bg-graph1">
      <Box className="grid-container">
        <Typography variant="h4" className="title">
          ESG ALMANAC – INDIA & GLOBAL
        </Typography>
        <Typography variant="body1" textAlign="center" marginTop="-20px">
          Roll Over & Click the Almanac to Get Your Complimentary Access to the
          respective Version.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Link to="/contact-us">
              <Box className="global-india-img">
                <img src={India} alt="" />
              </Box>
            </Link>
            <Typography>
              The present version of the ESG Almanac – India runs from April
              2022 to Mar 2023. The access is currently available up-to
              September 2022 (end of Q2). Thereafter, free access will be
              enabled after the end of the current quarter.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Link to="/contact-us">
              <Box className="global-india-img">
                <img src={Global} alt="" />
              </Box>
            </Link>
            <Typography>
              The present version of the ESG Almanac – Global runs from January
              2022 to December 2022. The access is currently available up-to
              September 2022 (end of Q3). Thereafter, free access will be
              enabled after the end of the current quarter.
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Typography fontStyle="italic" variant="subtitle5">
          *Please note, the above ESG Almanacs – India and Global would need
          expert customization as per the respective country laws, industry,
          choice of ESG performance standards and the maturity cycle of ESG in
          the organization.
        </Typography>
      </Box>
    </Box>
  );
}

export default GlobalIndia;
