import React from "react";
import { Box, Typography } from "@mui/material";

function Banner() {
  return (
    <Box className="podcast-banner">
      <Box className="grid-container">
        <Typography variant="h4" color="white">
          Podcasts
        </Typography>
        <Typography>
          TUNE IN & LISTEN TO OUR HANDPICKED CONVERSATIONS
        </Typography>
      </Box>
    </Box>
  );
}

export default Banner;
