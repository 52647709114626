import { TextField, Typography } from "@mui/material";
import React from "react";

function Form() {
  return (
    <form className="form">
      <div>
        <Typography variant="h6">Name *</Typography>
        <TextField variant="outlined" className="text-feild" />
      </div>
      <div>
        <Typography variant="h6">Designation </Typography>
        <TextField variant="outlined" className="text-feild" />
      </div>
      <div>
        <Typography variant="h6">Organisation </Typography>
        <TextField variant="outlined" className="text-feild" />
      </div>
      <div>
        <Typography variant="h6">Contact Number *</Typography>
        <TextField variant="outlined" className="text-feild" />
      </div>
      <div>
        <Typography variant="h6">Email ID</Typography>
        <TextField variant="outlined" className="text-feild" />
      </div>
      <div>
        <Typography variant="h6"> Message </Typography>
        <TextField
          multiline
          rows={4}
          variant="outlined"
          sx={{
            border: "none",
            borderRadius: "10px",
            width: "100%",
            color: "gray",
          }}
        />
      </div>

      <button className="common-button-opposite">Submit</button>
    </form>
  );
}

export default Form;
