import React from "react";
import { Box, Typography } from "@mui/material";
import Lumen1 from "../../img/Lumen1.png";
import Lumen2 from "../../img/Lumen2.jpeg";
import Lumen3 from "../../img/Lumen3.png";
import Lumen4 from "../../img/Lumen4.png";
import Lumenbg from "../../img/Lumenbg.png";

function Lumen() {
  return (
    <Box className="lumen-wrapper">
      <Typography variant="h1" className="text-black">
        Legal Acumen Meets
        <br /> Technology
      </Typography>
      <br />
      <Typography variant="body1" className="text-black">
        Machine Learning, Super BI, Integrated CRM and Power of Blockchain
        (Polygon)
      </Typography>
      <Box className="img-section">
        <Box className="img-box">
          <img src={Lumen2} alt="" data-aos="zoom-in" />
          <img src={Lumen3} alt="" data-aos="zoom-in" data-aos-delay="300" />
        </Box>
        <img src={Lumenbg} alt="" className="bg-img" />
        <Box className="img-box">
          <img src={Lumen1} alt="" data-aos="zoom-in" data-aos-delay="400" />
          <img src={Lumen4} alt="" data-aos="zoom-in" data-aos-delay="600" />
        </Box>
      </Box>
    </Box>
  );
}

export default Lumen;
