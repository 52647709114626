import React from "react";
import { Box, Typography } from "@mui/material";
import gri from "../../../img/gri.png";

function HeteroESG() {
  return (
    <Box className="grid-container" data-aos="fade-up" data-aos-delay="600">
      <Typography variant="h3" className="title">
        Handles ESG Heterogeneity
      </Typography>
      <Typography
        variant="h5"
        marginTop="-20px"
        textAlign="center"
        color="#c87533"
      >
        Easily configures the challenges of Heterogeneity in ESG Frameworks
      </Typography>
      <br />
      <Typography textAlign="center" width="80%" margin="auto">
        Following a centralized approach to manage your ESG requirements, we
        provide a collection of aggregated data on various performance standards
        across the globe. The centralized repository enables companies to manage
        its reporting and assessment across business units. We facilitate
        individual ESG focused assessment on different standards including GRI,
        TCFD, BRSR, UN PRI and so on.
      </Typography>
      <Box className="img-for-gri">
        <img src={gri} alt="" />
      </Box>
      <br />
      <br />
      <Typography
        variant="subtitle1"
        textAlign="center"
        margin="auto"
        width="80%"
        color="#666666"
      >
        All logos, icons, and domain are proprietary to the respective
        organisations. Nothing contained on the website should be construed as
        granting, by implication, estoppel, or otherwise, any license or right
        to use logos, icons, and domain names displayed on this website.
      </Typography>
    </Box>
  );
}

export default HeteroESG;
