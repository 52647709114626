import React from "react";
import { Box } from "@mui/material";

import "./index.scss";
import ExpertiseBanner from "./Banner";
import Highlights from "./Highlights";
import KSGComp from "./KSGComp";
import Goals from "./Goals";
import Commits from "./Commits";
import TryEsg from "../Common/TryEsg";
import Footer from "../footer";

function Expertise() {
  return (
    <Box className="index-wrapper">
      <Box className="expertise-wrapper">
        <ExpertiseBanner />
        <Highlights />
        <KSGComp />
        <Goals />
        <Commits />
        <TryEsg />
      </Box>
      <Footer />
    </Box>
  );
}

export default Expertise;
