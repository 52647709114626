import { Box, Typography } from "@mui/material";
import React from "react";

function Banner() {
  return (
    <Box className="people-bg">
      <Box className="meet-text">
        <Typography variant="h1" marginTop="20%" color="white">
          Meet Our Team
        </Typography>
        <Typography variant="body1" color="white">
          With ESG at heart, we are a bunch of dedicated ESG professionals that
          come together to do our bit to make this world a better place to live.
          We’re here to serve you with the best possible solutions to your ESG
          Journey.
        </Typography>
      </Box>
      <Box className="overlay-people" />
    </Box>
  );
}

export default Banner;
