import React from "react";
import { Box, Typography } from "@mui/material";

import Info from "./Info";

function Pricingbanner() {
  return (
    <Box>
      <Typography variant="h1" className="title">
        Pricing
      </Typography>
      <Info />
      <Text />
    </Box>
  );
}

export default Pricingbanner;

const Text = () => {
  return (
    <Typography variant="body1" className="title">
      Once we receive request in 24 working hours, our team would connect with
      you and take forward the proposed onboarding. The payments would be done
      on annual/six monthly basis online. If you have an offline onboarding
      process through a purchase order – the same would be considered by our
      relationship team.
    </Typography>
  );
};
