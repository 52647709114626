import React from "react";
import { Grid, Typography, Box } from "@mui/material";

function IndudtrySpecific() {
  return (
    <Box className="grey-bg-graph1" data-aos="fade-up">
      <Box className="grid-container">
        <Typography variant="h4" className="title-depth">
          Industry Specific Platform
        </Typography>
        <Typography textAlign="center">
          Various Industries covered as per global taxonomy for e.g., SASB
        </Typography>
        <br /> <br />
        <Grid container spacing={2} className="listing-wrapper">
          <Grid item md={4}>
            <li>Advertising & Marketing</li>
            <li> Aerospace & Defence </li>
            <li>Agricultural Produce </li>
            <li>Air Freight & Logistics</li>
            <li>Airlines </li>
            <li>Alcoholic Beverages</li>
            <li>Apparel , Accessories & Footwear </li>
            <li>Appliance Manufacturing</li>
            <li>Asset Management, Mutual Fund & Custody Activities</li>
            <li>Auto Parts </li>
            <li>Automobiles</li>
            <li>Auto PartsBatteriesAuto Parts </li>
            <li>Biofuels </li>
            <li>Biotechnology</li>
            <li>Builders</li>
            <li>Building Products & Furnishings</li>
            <li>Car Rental & Leasing</li>
            <li>Casinos & Gaming</li>
            <li>Chemicals</li>
            <li>Coal Operations</li>
            <li>Commercial Banks</li>
            <li>Commercial Vehicle Operators</li>
            <li>Construction Materials</li>
            <li>Containers & Packaging</li>
            <li>Cruise Lines</li>
            <li>Defence Products</li>
            <li>Drug Retailers</li>
          </Grid>
          <Grid item md={4}>
            <li>E-commerce</li>
            <li>Education</li>
            <li>Electric Utilities & Power Generators </li>
            <li>Electrical & Electronic Equipment</li>
            <li>Engineering & Construction Services</li>
            <li>Fertilizers</li>
            <li>FMCG</li>
            <li>Food Retailers & Distributors</li>
            <li>Forestry Management</li>
            <li>Fuel Cells & Industrial Batteries</li>
            <li>Gas Utilities & Distributors</li>
            <li>Hardware</li>
            <li>Health Care Delivery</li>
            <li>Hotels & Lodging</li>
            <li>Household & Personal Products</li>
            <li>Industrial Machinery & Goods</li>
            <li>Insurance – General</li>
            <li>Insurance – Life</li>
            <li>Insurance Brokers</li>
            <li>Internet Media & Services</li>
            <li>Investment Banking</li>
            <li>Investment Broking </li>
            <li>Iron & Steel Producers</li>
            <li>Leisure Facilities</li>
            <li>Marine Transportation</li>
            <li>Meat, Poultry & Dairy</li>
            <li>Media & Entertainment</li>
            <li>Medical Equipment & Supplies</li>
            <li>Metals </li>
          </Grid>
          <Grid item md={4}>
            <li>Mining</li>
            <li>NBFCs</li>
            <li>Non-Alcoholic Beverages</li>
            <li>Oil & Gas </li>
            <li>Pharmaceuticals</li>
            <li>Processed Foods</li>
            <li>Professional & Commercial Services</li>
            <li>Pulp & Paper Products</li>
            <li>Real Estate Services</li>
            <li>Restaurants</li>
            <li>Retail distribution</li>
            <li>Retail malls</li>
            <li>Security & Commodity Exchanges</li>
            <li>Semiconductor</li>
            <li>Software & IT Services</li>
            <li>Solar Technology & Project Developers</li>
            <li>Technology Hardware </li>
            <li>Telecommunication Services</li>
            <li>Textiles</li>
            <li>Tobacco </li>
            <li>Tourism</li>
            <li>Toys & Sporting Goods</li>
            <li>Waste Management</li>
            <li>Water Utilities & Services</li>
            <li>Wind Technology & Project Developers</li>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default IndudtrySpecific;
