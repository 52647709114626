import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function TheHonor() {
  return (
    <Box className="grid-container">
      <Typography variant="h4" className="title">
        HONOR CODE
      </Typography>
      <Typography className="text-align-center">
        As a result, all college students became ambassadors of PPP. They
        displayed their digital badges with responsibility. The PPP platform
        would not be responsible for any act against any laws or nuisance
        activities under PPP, and participating colleges shall take suitable
        disciplinary measures.
      </Typography>
      <YoutubeEmbed />
      <Typography variant="h4" className="title">
        STAY TUNED
      </Typography>
      <Typography className="text-align-center">
        Forthwith, it is our endeavour to continue with such initiatives as an
        annual event to endorse the significance of environmental, social and
        governance along with upholding human resource ethics. For any further
        details, you may write to
        <Link to="" className="text-green">
          esglab@dhirassociates.com
        </Link>
      </Typography>
    </Box>
  );
}

export default TheHonor;

const YoutubeEmbed = ({ embedId }) => {
  return (
    <Box className="grid-container">
      <iframe
        width="100%"
        height="400"
        src="https://www.youtube.com/embed/i-2-H2uvuAo"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </Box>
  );
};
