import { Box, Typography } from "@mui/material";
import React from "react";

function Banner() {
  return (
    <>
      <Box className="esg-framework-banner" data-aos="fade-up">
        <Box className="overlay-bg-esg" />
        <Box className="grey-bg-with-text" data-aos="zoom-out" data-aos-delay="600">
          <Box className="text-wrapper">
            <Typography variant="h5">An ESG Solution where</Typography>
            <br />
            <Typography variant="h1">
              Compliance
              <br /> Meets Tech
            </Typography>
            <br />
            <Typography variant="subtitle3" color="rgba(255, 255, 255, 0.74)">
              A comprehensive cloud-based management system that identifies,
              tracks, and monitors various statutory and regulatory requirements
              across industries.
            </Typography>
          </Box>
        </Box>
      </Box>
      <br />
      <Text />
    </>
  );
}

export default Banner;

const Text = () => {
  return (
    <Box className="grid-container" padding="30px">
      <Typography variant="body1">
        In line with digital transformation, our compliance management system is
        developed on a technology model which is a combination of digital
        programming and IT operations. The tool helps simplify organization-wide
        compliance requirements in an integrated manner. We further assist with
        identifying risks and improve collaboration and communication across
        your teams with intuitive graphical dashboards and charts that provide
        critical real-time representation into compliance processes.
      </Typography>
      <br /> <br />
      <Typography variant="body1">
        We provide a comprehensive cloud-based management system that
        identifies, tracks, and monitors various statutory and regulatory
        requirements across industries. It assists with systematic management of
        documents, activities, internal audits and also training sessions, thus
        making operational process more efficient. We provide an extensive
        knowledge repository covering a wide ambit of central and state
        legislations.
      </Typography>
    </Box>
  );
};
