import { Box, Typography } from "@mui/material";
import React from "react";
import Footer from "../../components/footer";
import TryEsg from "../../components/Common/TryEsg";

function RefundPolicy() {
  return (
    <>
      <Box className="legal-bg">
        <Box className="overlay-legal" />
        <Typography
          variant="h1"
          color="white"
          position="relative"
          zIndex={3}
          marginTop="-30px"
        >
          Cancellation & Refund Policy
        </Typography>
      </Box>
      <Desc />
      <TryEsg />
      <Footer />
    </>
  );
}

export default RefundPolicy;

const Desc = () => {
  return (
    <>
      <Box className="grid-container">
        <Typography variant="h4">Cancellation & Refund Policy</Typography>
        <br />
        <Typography marginBottom={10}>
          No refunds on cancellation otherwise as required by any law would be
          the effective policy as the buy decision has been done after fair
          diligence & evaluation by the customer after reviewing sufficient
          information and demo trials, if any availed. Cancellation based on
          technical issues – has to be based on query generated on CRM.
          Thereafter a sufficient cure period would be mutually decided and even
          after the same if the query is unresolved, the pro-rata refund would
          be done by issuing credits of equivalent amount of unused subscription
          period to avail other services of ESGFIT.IN or its partners .
          <br />
          <br />
          The Indemnity part and Limitation of liability would be applicable as
          per the Terms of Use of the website agreement available on the
          Esgfit.in
        </Typography>
      </Box>
    </>
  );
};
