import React from "react";
import { Box, Typography } from "@mui/material";

function Banner() {
  return (
    <Box className="planet-banner-bg">
      <Typography variant="h4" color="white">
        The Planet People Program
      </Typography>
      <Typography>
        India’s 1st industry – academia human chain commitment towards
        Sustainability
      </Typography>
    </Box>
  );
}

export default Banner;
