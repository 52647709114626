import React from "react";
import { Box } from "@mui/material";

import "./index.scss";
import Faq from "./Faq";
import TryEsg from "../Common/TryEsg";
import CommonDivider from "../Common/CommonDivider";
import Pricingbanner from "./Pricingbanner";
import Footer from "../footer";

function Pricing() {
  return (
    <Box className="index-wrapper">
      <Box className="pricing-wrapper">
        <Box className="pricing-main-wrapper">
          <Pricingbanner />
          <CommonDivider />
          <Faq />
        </Box>
        <TryEsg />
      </Box>
      <Footer />
    </Box>
  );
}

export default Pricing;
