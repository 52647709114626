import { Box, Typography } from "@mui/material";
import React from "react";

function StayTuned() {
  return (
    <Box className="grid-container">
      <Typography variant="h4" className="title">
        STAY TUNED
      </Typography>
      <Typography>
        Forthwith, it is our endeavour to continue with such initiatives as an
        annual event to endorse the significance of environmental, social and
        governance along with upholding human resource ethics. For any further
        details, you may get in touch with the ESG Mentors Manasi
        Deshpande/Yashwardhan Sharma by writing to{" "}
        <a
          href="/"
          className="text-green"
          style={{ textDecoration: "underline" }}
        >
          esglab@dhirassociates.com
        </a>
      </Typography>
      <br />
      <br />
      <Typography variant="body3" fontStyle="italic">
        Disclaimer –
        <span className="font-circular-md">
          {" "}
          The marathon organisation, selection of registrations and any other
          shortlisting criteria is at the sole discretion of Dhir & Dhir
          Associates.
        </span>
      </Typography>
    </Box>
  );
}

export default StayTuned;
