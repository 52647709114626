import React, { useEffect, useLayoutEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./Style/App.scss";
import ContactUs from "./components/ContactUs";
import Navbar from "./components/Navbar";
import { Box } from "@mui/material";
import Pricing from "./components/Pricing";
import Expertise from "./components/Expertise";
import Home from "./components/Home";
import About from "./pages/About";
import People from "./pages/People";
import ESGFramework from "./pages/Solutions/ESGFramework";
import ESGCompliance from "./pages/Solutions/Compliance";
import PlanetProgram from "./pages/Innovation/PlanetProgram";
import Almanac from "./pages/Innovation/Almanac";
import Podcast from "./pages/Innovation/Podcast";
import RL2021 from "./pages/Innovation/ResearchLab/2021";
import RL2022 from "./pages/Innovation/ResearchLab/2022";
import Error from "./components/Error";
import Legal from "./pages/Legal";
import TermsConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import Aos from "aos";
import "aos/dist/aos.css";
import "aos/dist/aos.js";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};


function App() {
  useEffect(() => {
    Aos.init({duration: 600});
  }, []);
  return (
    <BrowserRouter>
      <Box className="App">
        {" "}
        <Wrapper>
          <Navbar />
          <Routes path="/">
            <Route index element={<Home />} />
            <Route path="/expertise" element={<Expertise />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/about" element={<About />} />
            <Route path="/people" element={<People />} />
            <Route path="/esg-framework" element={<ESGFramework />} />
            <Route
              path="/statutory-regulatory-compliance"
              element={<ESGCompliance />}
            />
            <Route path="/planet-programme" element={<PlanetProgram />} />
            <Route path="/esg-almanac" element={<Almanac />} />
            <Route path="/podcast" element={<Podcast />} />
            <Route path="/research-lab-2021" element={<RL2021 />} />
            <Route path="/research-lab-2022" element={<RL2022 />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="/terms-and-conditions" element={<TermsConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </Wrapper>
      </Box>
    </BrowserRouter>
  );
}

export default App;
