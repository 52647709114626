import React from "react";
import { Box, Typography } from "@mui/material";
import whiteloader from "../../img/loader-white.gif";
import homeIllus from "../../img/illus-home.png";
import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect";

function HomeBanner() {
  return (
    <Box className="homebanner-wrapper">
      <Box className="home-text-container">
        <Box className="white-loader">
          <img src={whiteloader} alt="" />
        </Box>
        <Typography variant="h1" className="enahance-text">
          Enhance Your ESG Journey<br></br>
          <span style={{ display: "flex", justifyContent: "center" }}>
            ESG&nbsp;
            <Typewriter
              options={{
                strings: [
                  "is Measurable Value",
                  "Drives Investments",
                  "Sets Global Benchmarks",
                ],
                autoStart: true,
                loop: true,
                delay: 25,
                deleteSpeed: 25,
              }}
            />
          </span>
          {/*Drives Investments Sets Global Benchmarks  */}
        </Typography>
        <Typography variant="subtitle3" className="desc-text">
          Gear Up as Non-Compliance can be Costly Get the best of DIY DIFM &
          meet Global Standards Explore options starting as low as $1 per Day
        </Typography>
        <br />
        <Link to="/expertise" className="orange-button">
          Learn more
        </Link>
      </Box>
      <img
        className="homeIllus"
        src={homeIllus}
        alt=""
        style={{
          width: "80vw",
          marginBottom: "25px",
          position: "absolute",
          top: "68vh",
        }}
      />
    </Box>
  );
}

export default HomeBanner;
