import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import TryEsg from "../../components/Common/TryEsg";
import Footer from "../../components/footer";

function Legal() {
  return (
    <>
      <Box className="legal-bg">
        <Box className="overlay-legal" />
        <Typography
          variant="h3"
          color="white"
          position="relative"
          zIndex={3}
          marginTop="-30px"
        >
          Yes, It’s Important.
        </Typography>
        <Typography variant="h1" color="white" position="relative" zIndex={3}>
          Legal
        </Typography>
      </Box>
      <Cards />
      <TryEsg />
      <Footer />
    </>
  );
}

export default Legal;

const Cards = () => {
  return (
    <Box className="grey-bg-graph1">
      <Box className="grid-container">
        <Grid container padding="20px auto" spacing={4}>
          {data.map((item, index) => (
            <Grid item md={4} key={index}>
              <Box className="card-legal">
                <Typography variant="h5">{item.title}</Typography>
                <br />
                <Typography variant="body1">{item.desc}</Typography>
                <br />
                <Link
                  to={item.link}
                  style={{
                    width: "160px",
                    fontWeight: 600,
                    padding: "15px 10px ",
                  }}
                  className="common-button-opposite"
                >
                  Continue Reading
                </Link>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

const data = [
  {
    title: "Terms & Conditions",
    desc: "Please read the website’s (ESGFIT.IN) Terms and Conditions forvigilant website usage. The website’s terms of use shall standardize your access to and use of the website. The website shall be accessible only on the pre-requisite that you agree to the terms.",
    link: "/terms-and-conditions",
  },
  {
    title: "Privacy Policy",
    desc: "At ESG FIT, accessible from www.esgfit.in, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by ESG FIT and the manner we access it.",
    link: "/privacy-policy",
  },
  {
    title: "Cancellation & Refund Policy",
    desc: "No refunds on cancellation otherwise as required by any law would be the effective policy as the buy decision has been done after fair diligence & evaluation by the customer after reviewing sufficient information",
    link: "/refund-policy",
  },
];
