import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import legal from "../../../../img/legal.jpeg";

function YetAgain() {
  return (
    <Box className="grid-container">
      <Grid container spacing={10}>
        <Grid item xs={12} md={6}>
          <Box className="graph-img">
            <img src={legal} alt="" />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h3">In A NutShell</Typography>
          <br />
          <Typography variant="subtitle5">
            Dhir & Dhir Associates organised the first of its kind “The Virtual
            Legal Marathon on ESG – 24 hour Live Research Lab” where more than
            450 aspiring law students registered from around 94 law colleges &
            universities and generated insightful research papers. This
            initiative was also registered as a UNEP (United Nations Environment
            Programme) Event for World Environment Day Celebrations 2021.
            <br />
            <br />
            The initiative garnered tremendous global attention and endorsed the
            significance of environmental, social and governance along with
            upholding human resource ethics. The Inaugural Ceremony was flagged
            off on 3rd June 2021 and witnessed encouraging participation under
            the patronage of notable industry experts.
            <br />
            <br />
            The research by the team of participants was recorded and documented
            in pre-established templates, meticulously examined by the Jury to
            decide the winners of India’s First Virtual Legal Marathon – 24 Hour
            Research Lab, who have been offered internships with the firm where
            they will undergo training to hone their skills and implement their
            knowledge expertise.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default YetAgain;
