import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import dd from "../../../img/D&D.png";
import uu from "../../../img/U&U.jpeg";
import ficl from "../../../img/FICL.jpeg";
import { Link } from "react-router-dom";

function Patrons() {
  return (
    <Box className="grid-container">
      <Typography variant="h4" className="title">
        PATRONS OF THE PROGRAM
      </Typography>
      <PatronsData /> <br />
      <Typography>
        As key anchors Dhir & Dhir Associates and Uttaranchal University were
        desirous of providing one of its kind & a first platform globally for
        to-be lawyers to voice their thoughts in an organized and controlled
        manner. An attempt was made to have more law colleges endorse the
        program and create meaningful interactions and voices on the planet &
        people. This was the first human chain of to-be lawyers in a structured
        manner in the world. Dehradun is one of the country’s most eco-sensitive
        capital cities; the program’s primary seat is being set up in the said
        city. Participating partners could create more local seats along with
        the above key anchors.
      </Typography>
      <br />
      <Typography>
        Disclaimer: In no way were the key anchors responsible for any
        hateful/rogue acts and/or speech by the student members in this program.
        The to-be lawyers themselves ran the program.
      </Typography>
    </Box>
  );
}

export default Patrons;

const PatronsData = () => {
  return (
    <Grid container spacing={2}>
      {data.map((item, index) => (
        <Grid item md={4} xs={12}>
          <Link onClick={() => window.open("https://www.dhirassociates.com/")}>
            <Box className="patrons-box">
              <Box className="graph-img" key={index}>
                <img src={item.src} alt="" />
              </Box>
              <Typography variant="h5">{item.title} </Typography>
              <Typography>{item.subtitle} </Typography>
              <Link to="" className="text-green">
                {item.link}
              </Link>
            </Box>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};
const data = [
  {
    src: dd,
    title: "CORE PATRON",
    subtitle: "Dhir & Dhir Associates",
    link: "Know More",
  },
  {
    src: uu,
    title: "FIRST ACADEMIC PATRON",
    subtitle: "Uttaranchal University",
    link: "Know More",
  },
  {
    src: ficl,
    title: "INDUSTRY PATRON",
    subtitle: "FICL",
    link: "Know More",
  },
];
