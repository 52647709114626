import React from "react";
import { Box, Typography } from "@mui/material";

import Collage from "../../img/Collage.jpg";
import Lumen4 from "../../img/Lumen4.png";
import research from "../../img/research.png";
import { Link } from "react-router-dom";

function Highlights() {
  return (
    <Box className="hightlight-wrapper">
      <Box className="text-section">
        <Typography variant="h3">
          We’re Here to Demystify ESG.
          <br />
          Understand & Implement it for You.
        </Typography>
        <Typography variant="h6">
          Environmental, Social and Governance (ESG) are three critical
          <br />
          components that help measure the sustainability, societal and
          <br />
          compliance related risks and opportunities pertaining to the business
          <br />
          operations of a company.
        </Typography>
      </Box>
      <Box className="highlight-section">
        <Box className="highlight-img-wrapper">
          <Box className="highlight-img">
            <div>
              <img src={Collage} alt="" className="collage" />
            </div>
            <div>
              <img src={Lumen4} alt="" className="smcollage" />
            </div>
          </Box>
          <Box className="text-of-highlight">
            <Typography variant="h5" style={{ fontweight: 700 }}>
              Here’s our much-in-
              <br />
              demand ESG Handbook on <br /> Applicable Laws &<br /> Regulations.
            </Typography>
            <br />
            <Link
              to="/contact-us"
              className="common-button-opposite button-interested"
            >
              I'm interested
            </Link>
          </Box>
        </Box>
        <Box className="highlights">
          <div>
            <img src={research} alt="" />
          </div>
          <Typography variant="h5">ESGFIT Highlights</Typography>
          <Typography variant="body1">
            Monitor Short/Long Term Financial Performance
          </Typography>
          <Typography variant="body1">
            Empower Resources More than Ever
          </Typography>
          <Typography variant="body1">Global Performance Standards</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Highlights;
