import React from "react";
import { Box, Typography } from "@mui/material";

import dashboard from "../../../img/esgdashboard.png";

function GreenPlanning() {
  return (
    <>
      <Box className="grey-bg-graph1">
        <Box className="grid-container">
          <Typography variant="h4" className="title-depth">
            Helps in Avoiding Greenwashing
          </Typography>
          <Typography
            variant="h5"
            marginTop="-20px"
            textAlign="center"
            color="#c87533"
          >
            Creates trails & evidence to safeguard by periodic reporting &
            audits
          </Typography>
          <br />
          <Typography textAlign="center">
            ESG greenwashing is in disguise cheating upon your stakeholders. To
            get micro control of your adherence to local laws of the country
            configuring all laws is a pre-condition to any disclosures in annual
            report or public domain. In esgfit.in every law in India has been
            given a ESG quotient and all relevant compliances within acts/rules
            applicable have been configured. For all countries other than India
            in our unique methodology of DIY & DIFM, please raise a request here
            for our team to connect and configure the entire gamut of laws and
            their respective ESG Obligations. Truly first of its kind offering
            globally!
          </Typography>
        </Box>
      </Box>
      <Box className="grid-container">
        <Typography variant="h4" className="title-depth">
          Visual BI (Business Intelligence) for Management Planning
        </Typography>
        <Typography textAlign="center">
          Heat Maps on ESG elements gives the management tangible
          decision-making information e.g., resource allocation, CAPEX, OPEX,
          underperforming ESG Elements etc. Our risk assessment is based on a
          comprehensive evaluation of company disclosures made against benchmark
          global practices. Based on the output shared by the company, our
          software generates a heat map showcasing the company’s performance
          against different ESG risks and opportunities associated with the
          business operations. It provides a holistic overview of the company’s
          global standing on the ESG front.
        </Typography>
        <br />
        <Box className="graph-img" width="80%" margin="auto">
          <img src={dashboard} alt="" />
        </Box>
      </Box>
    </>
  );
}

export default GreenPlanning;
