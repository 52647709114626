import React from "react";
import { Box } from "@mui/material";
import AboutPodcasts from "./AboutPodcasts";
import Banner from "./Banner";
import Details from "./Details";
import Footer from "../../../components/footer";

function Podcast() {
  return (
    <Box className="index-wrapper">
      <Banner />
      <Details />
      <AboutPodcasts />
      <Footer />
    </Box>
  );
}

export default Podcast;
