import React from "react";
import { Box, Typography } from "@mui/material";
import circle from "../../img/circle.png";
import { BsFillStarFill } from "react-icons/bs";
import loader from "../../img/Loader.gif";
import people1 from "../../img/People/people1.jpeg";
import people2 from "../../img/People/people2.jpeg";
import people3 from "../../img/People/people3.jpeg";
import people4 from "../../img/People/people4.jpeg";
import people5 from "../../img/People/people5.jpeg";
import people6 from "../../img/People/people6.jpeg";

function Commits() {
  return (
    <Box className="commit-wrapper">
      <Box className="img-people">
        <img src={circle} alt="" className="img-wrapper" />
        <Box className="people1-img">
          <img src={people1} alt="" data-aos="zoom-in" data-aos-delay="500" />
        </Box>
        <Box className="people2-img">
          <img src={people2} alt="" data-aos="zoom-in" data-aos-delay="700" />
        </Box>
        <Box className="people3-img">
          <img src={people3} alt="" data-aos="zoom-in" data-aos-delay="900"/>
        </Box>
        <Box className="people4-img">
          <img src={people4} alt="" data-aos="zoom-in" data-aos-delay="1000"/>
        </Box>
        <Box className="people5-img">
          <img src={people5} alt="" data-aos="zoom-in" data-aos-delay="1100"/>
        </Box>
        <Box className="people6-img">
          <img src={people6} alt="" data-aos="zoom-in" data-aos-delay="1300"/>
        </Box>
      </Box>
      <Box className="we-are-commited">
        <Typography variant="h2">
          We’re <br /> Committed.
        </Typography>
        <Box className="star">
          <BsFillStarFill className="star-svg" />
          <BsFillStarFill className="star-svg" />
          <BsFillStarFill className="star-svg" />
          <BsFillStarFill className="star-svg" />
          <BsFillStarFill className="star-svg" />
        </Box>
        <Typography variant="subtitle3" className="desc">
          To help, guide, and support you in navigating the quagmire of ESG
          compliances under myriad laws and regulatory diktat to emerge as an
          ESG-compliant entity. Our team works tirelessly with full commitment
          to support you in achieving the highest levels of ESG compliance.
        </Typography>
        <br />
        <div>
          <img src={loader} alt="" />
        </div>
      </Box>
    </Box>
  );
}

export default Commits;
