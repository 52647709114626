import React from "react";
import { Box, Typography } from "@mui/material";
import graph1 from "../../../img/graph1.png";

function Graph1() {
  return (
    <Box className="grey-bg-graph1">
      <Box className="grid-container">
        <Typography variant="h4" className="title-depth">
          Depth of Knowledge Repository - <br /> Central Acts Coverage across
          <br />
          Different Ministries
        </Typography>
        <Box className="graph-img">
          <img src={graph1} alt="" />
        </Box>
      </Box>
    </Box>
  );
}

export default Graph1;
