import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import program1 from "../../../img/Program/program1.jpeg";
import program2 from "../../../img/Program/program2.jpeg";
import program3 from "../../../img/Program/program3.jpeg";
import program4 from "../../../img/Program/program4.jpeg";
import program5 from "../../../img/Program/program5.jpeg";
import program6 from "../../../img/Program/program6.jpeg";

function TheProgram() {
  return (
    <Box className="grey-bg-graph1">
      <Box className="grid-container">
        <Typography variant="h4" className="title">
          THE PROGRAM
        </Typography>
        <Typography>
          With the objective of creating a human chain focused on addressing the
          current global climate crisis and other issues about the environment
          and social components, i.e., the natural overutilization of the
          natural habitat, the core patron – Dhir & Dhir Associates launched
          ‘The Planet-People Program’ on 8th April 2022 at Dehradun (India)
          campus of its first academic patron Uttaranchal University & Industry
          Patron – FICL. A revolutionary project designed to facilitate a common
          platform for young students/ budding lawyers to voice their opinions,
          undertake initiatives and implement desired plans to address the
          global climate crisis and other critical issues about the planet’s
          well-being and people.
        </Typography>
        <br />
        <PeopleImg />
      </Box>
    </Box>
  );
}

export default TheProgram;

const PeopleImg = () => {
  return (
    <Grid container spacing={2}>
      {data.map((item, index) => (
        <Grid item md={4} xs={6}>
          <Box className="graph-img" key={index}>
            <img src={item.src} alt="" />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

const data = [
  {
    src: program1,
  },
  {
    src: program2,
  },
  {
    src: program3,
  },
  {
    src: program4,
  },
  {
    src: program5,
  },
  {
    src: program6,
  },
];
