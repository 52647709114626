import React from "react";
import { Box, Typography } from "@mui/material";
import people1 from "../../img/People/people1.jpeg";
import people2 from "../../img/People/people2.jpeg";
import people3 from "../../img/People/people3.jpeg";
import people4 from "../../img/People/people4.jpeg";
import people5 from "../../img/People/people5.jpeg";
import people6 from "../../img/People/people6.jpeg";

function OurPeople() {
  return (
    <Box className="people-wrapper">
      {data.map((item, index) => (
        <Box className="people-card" key={index}>
          <Box>
            <img src={item.src} alt="" className="people-img" />
          </Box>
          <br />
          <Typography variant="subtitle5" color="grey" letterSpacing="1px">
            {item.title}
          </Typography>
          <br />
          <Typography variant="h5">{item.desc}</Typography>
        </Box>
      ))}
    </Box>
  );
}

export default OurPeople;

const data = [
  {
    src: people1,
    title: "CHIEF EXECUTIVE OFFICER",
    desc: "Poonam Bisht",
  },
  {
    src: people2,
    title: "PARTNER - ESG ADVISORY DESK",
    desc: "Sonal Verma",
  },

  {
    src: people4,
    title: "PRINCIPAL ASSOCIATE - ESG ADVISORY DESK",
    desc: "Amey Patwardhan",
  },
  {
    src: people3,
    title: "ASSOCIATE - ESG ADVISORY DESK",
    desc: "Abhijeet Kashyap",
  },
  {
    src: people5,
    title: "ASSOCIATE - ESG ADVISORY DESK",
    desc: "Rutambhara Mishra",
  },
  {
    src: people6,
    title: "ASSOCIATE - ESG ADVISORY DESK",
    desc: "Saurabh Sharma",
  },
];
