import React from "react";
import { Box } from "@mui/material";
import Banner from "./Banner";
import ExpertsView from "./ExpertsView";
import Statistics from "./Statistics";
import StayTuned from "./StayTuned";
import Winners from "./Winners";
import YetAgain from "./YetAgain";
import Footer from "../../../../components/footer";

function RL2021() {
  return (
    <Box className="index-wrapper">
      <Banner />
      <YetAgain />
      <Statistics />
      <Winners />
      <ExpertsView />
      <StayTuned />
      <Footer />
    </Box>
  );
}

export default RL2021;
