import React from "react";
import { Box, Typography } from "@mui/material";
import VFL from "../../img/VFL.png";
import { FiArrowDownCircle } from "react-icons/fi";
import { Link } from "react-router-dom";

function OurVision() {
  return (
    <Box className="our-vision">
      <Box className="left-section" data-aos="fade-up">
        <Typography variant="h2" className="text-black">
          Indian by Intelligence.
          <br />
          Global by Standards.
        </Typography>
        <br />
        <Typography variant="body1">
          Our vision is to help companies monitor and evaluate both short and
          long-term financial performance and business viability. We help you
          take charge of your ESG reporting with our best-in-class technologies
          including cloud adaptability, blockchain enabled data storing,
          multitenancy and on-premises hosting.
        </Typography>
        <br />
        <Link to="/contact-us">
          <Box className="book-session">
            <FiArrowDownCircle /> &nbsp;
            <Typography variant="h5" className="text-green">
              Book Your ESG Session
            </Typography>
          </Box>
        </Link>
      </Box>
      <Box className="right-section">
        <img src={VFL} alt="" />
      </Box>
    </Box>
  );
}

export default OurVision;
