import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function StayTuned() {
  return (
    <Box className="grid-container">
      <Typography variant="h4" className="title">
        STAY TUNED
      </Typography>
      <Typography variant="body1">
        There are a number of global initiatives undertaken to achieve goals for
        a sustainable & inclusive future, combat climate crisis and effectively
        address social issues. Furthermore, there are a numerous global
        standards and recommendations specially put together to serve as an
        accountable and transparent common ground for companies to analyse and
        report their sustainability impacts in a consistent and credible way.
        Some of the initiatives/standards include Sustainable Development Goals
        (SGDs), Global Reporting Initiative (GRI), International Financial
        Corporation (IFC), Sustainable Finance Disclosure Regulation (SFDR) and
        so on.
        <br />
        <br />
        The release of the Almanacs is very timely as the SEBI replaced the
        existing Business Responsibilities Reporting mechanism (BRR) with the
        Business Responsibility and Sustainable Reporting (BRSR) mechanism
        through SEBI Circular of 10th May 2021 on the said subject. The revised
        framework is expected to bring to the forefront more transparency and
        accountability, especially in line with different ESG parameters, which
        not just the Indian authorities but also global leaders undertake as
        crucial and essential elements to be administered by companies in
        today’s day and age.
        <br />
        <br />
        Currently, foreign capital places major emphasis on assessing the
        alignment of ESG parameters by the investee companies. The rise in such
        specific assessments and disclosures has made ESG a crucial part of the
        impact investment process, whereby ESG is not merely a compliance
        requirement but it is the new way of doing business. In this background,
        our Almanacs will act as a beacon for navigating the increasing complex
        compliance requirements for the corporate to follow and address their
        concerns.
      </Typography>
      <br />
      <br />
      <Typography variant="h6">
        In case of any queries or further assistance please feel free to get in
        touch with The ESG Advisory Desk at Dhir & Dhir Associates. You can
        contact Sonal Verma, Partner – ESG Advisory, Dhir & Dhir Associates,
        &nbsp;
        <Link to="" className="text-green">
          sonal.verma@dhirassociates.com
        </Link>
        &nbsp; or reach out to him at +91 9987367094
      </Typography>
    </Box>
  );
}

export default StayTuned;
