import React from "react";
import { Box } from "@mui/material";
import spotify from "../../../img/spotify.png";
import Gpodcast from "../../../img/Gpodcast.png";

function Details() {
  return (
    <Box className="grid-container">
      <Box className="img-section-podcast">
        <Box className="graph-img">
          <a
            href="https://open.spotify.com/show/7axvFunKU1xVN24sg1bqBC"
            target="_blank"
          >
            <img src={spotify} alt="" />
          </a>{" "}
        </Box>
        <Box className="graph-img">
          <a
            href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy83NTdiZTdhNC9wb2RjYXN0L3Jzcw"
            target="_blank"
          >
            <img src={Gpodcast} alt="" />
          </a>
        </Box>
      </Box>
    </Box>
  );
}

export default Details;
