import React from "react";
import "./index.scss";
import { Box, Typography } from "@mui/material";
import SocialMedia from "../Common/SocialMedia";
import Form from "./Form";
import Footer from "../footer";
import TryEsg from "../Common/TryEsg";

function ContactUs() {
  return (
    <>
      <Box className="index-wrapper">
        <div className="contactus-second-wrapper">
          <div className="contact-us-main-wrapper">
            <div className="contact-us-container">
              <Typography variant="h1">
                Ready to begin your ESG Journey?
              </Typography>
              <Typography variant="h6">Let us know how we can help</Typography>
            </div>
          </div>
          <div className="contact-us-address-form">
            <div className="form-section">
              <Typography variant="body1">
                We're always here for you and your team
              </Typography>
              <br />
              <Form />
            </div>
            <div className="address-section">
              <Typography variant="h5">Address</Typography>
              <div className="addresses">
                <Typography variant="body1">
                  D 55, Defence Colony,
                  <br />
                  New Delhi-110024 <br />
                  Tel: 91 (11) 42410000 <br />
                  Fax: 91 (11) 42410091
                </Typography>
                <Typography variant="body1">
                  # 21 & 22, 3rd Floor,
                  <br />
                  Onlooker Building,
                  <br /> Sir P.M. Road, Fort,
                  <br /> Mumbai – 400001, India <br /> Tel: +91 (22) 67472284
                </Typography>
              </div>
              <br />
              <Typography variant="h5">Your ESG Session</Typography>
              <Typography variant="body1">
                Let us show you what ESGFIT can do for your team. Get in touch
                at
                <span className="text-green"> yes@esgfit.in</span>
              </Typography>
              <br />
              <Typography variant="h5">Customer Support</Typography>
              <Typography variant="body1">
                For any product or technical questions, reach us at
                <span className="text-green"> support@esgfit.in</span>
              </Typography>
              <br />
              <Typography variant="h5">Anything Else</Typography>
              <Typography variant="body1">
                Excited by the ESGFIT.IN story and want to explore further?
                Contact
                <span className="text-green"> explore@esgfit.in</span>
              </Typography>
              <br />
              <Typography variant="h5">Follow Us</Typography>
              <SocialMedia />
            </div>
          </div>
        </div>
      </Box>
      <TryEsg />
      <Footer />
    </>
  );
}

export default ContactUs;
