import React from "react";
import { Box, Typography } from "@mui/material";
import poster from "../../img/sdgposter.png";
import { Link } from "react-router-dom";

function Goals() {
  return (
    <Box className="goals-wrapper">
      <Typography variant="h2">
        We’re Aligned to <br /> The Sustainable Development Goals
      </Typography>
      <Typography variant="h5">17 Goals for People, for Planet</Typography>
      <Typography variant="subtitle2" className="subtitle-text">
        The Sustainable Development Goals are a universal call to action to end
        poverty, protect the planet and improve the lives and prospects of
        everyone, everywhere. The 17 Goals were adopted by all UN Member States
        in 2015, as part of the 2030 Agenda for Sustainable Development which
        set out a 15-year plan to achieve the Goals.
      </Typography>
      <Link to="#" onClick={() => window.open("https://sdgs.un.org/goals")}>
        <Box className="poster-img">
          <img src={poster} alt="" />
        </Box>
      </Link>
    </Box>
  );
}

export default Goals;
