import { Box, Button, TextField, Typography } from "@mui/material";
import React from "react";

function Error() {
  return (
    <Box className="index-wrapper">
      <Box className="error-wrapper" marginX="auto">
        <Typography variant="h2">
          Oops! This page can't befound
          <br /> anywhere.
        </Typography>
        <br />
        <Typography>
          We're sorry, the page you have looked for does not exist in our
          content! Perhaps you would like to <br /> go to our homepage or try
          searching below.
        </Typography>
        <br />
        <Box className="input-box">
          <TextField placeholder="Type to search" style={{ width: "50%" }} />
          <Button variant="contained" className="search-btn">
            Search
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Error;
