import { Box, Typography } from "@mui/material";
import React from "react";
import Footer from "../../components/footer";
import TryEsg from "../../components/Common/TryEsg";

function PrivacyPolicy() {
  return (
    <>
      <Box className="legal-bg">
        <Box className="overlay-legal" />
        <Typography
          variant="h1"
          color="white"
          position="relative"
          zIndex={3}
          marginTop="-30px"
        >
          Privacy Policy
        </Typography>
      </Box>
      <Desc />
      <TryEsg />
      <Footer />
    </>
  );
}

export default PrivacyPolicy;

const Desc = () => {
  return (
    <Box className="grid-container">
      <Typography variant="h4">Privacy Policy</Typography>
      <br />
      {data.map((item, index) => (
        <Box key={index}>
          {item.title && (
            <>
              <Typography variant="h6">{item.title}</Typography>
              <br />
            </>
          )}
          <Typography>{item.desc} </Typography>
          <br />
        </Box>
      ))}
    </Box>
  );
};
const data = [
  {
    desc: "At ESG FIT, accessible from www.esgfit.in, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by ESG FIT and the manner we access it.",
  },
  {
    desc: "This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in ESG FIT. This policy is not applicable to any information collected offline or via channels other than this website.",
  },
  {
    title: "Consent",
    desc: "You hereby consent to our Privacy Policy and agree to its terms by using our website.",
  },
  {
    desc: "This Privacy Notice explains the categories of personal information we collect about clients, how we use it, and who we share it with. The warning also explains the steps we take to protect your personal information. We also explain how you would ask us to",
  },
  {
    desc: (
      <Box>
        <li>Verify and modify the personal information we hold about you,</li>
        <li>Withdraw the consent you previously gave us,</li>
        <li> Refrain sending you specific messages,</li>
        <li>
          and Answer any questions you might have about our privacy practices.
        </li>
        <br />
        <Typography>
          The ESGFIT.IN is exempted from this notice. To reflect local customs
          and regulatory standards, our practices may be varied throughout the
          areas in which we operate.
        </Typography>
      </Box>
    ),
  },
  {
    title: "Information We Collect",
    desc: "The personal information you are asked to provide and the reasons you are asked to provide it will be made clear to you at the point we ask you to provide your personal information.",
  },
  {
    desc: "If you contact us directly, we may receive additional information about you, such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.",
  },
  {
    desc: "When you register, we may ask for your contact information, including name, company name, address, email address, and telephone number.",
  },
  {
    title: "The types of personal information we may obtain include;",
    desc: (
      <Box>
        <li>
          Individual and business contact information (such as name, company
          name, physical address, email address, and telephone or fax number)
        </li>
        <li>
          Information that permits us to verify an individual’s identification
        </li>
        <li>
          Names, email addresses, and telephone numbers of others to whom we are
          asked to send information
        </li>
        <li>
          Information provided in response to surveys or other customer
          engagements like whitepapers, webinars
        </li>
        <li>
          Username, password and other credentials used to access ESGFIT.IN
          services
        </li>
        <li>
          Social media handles, content and other data posted on our official
          social media pages or elsewhere on the Internet (such as other public
          locations), and data (such as email address and other information you
          allow to be shared) we obtain through ESGFIT.IN-related social media
          apps, tools, widgets and plug-ins (including third-party login
          services
        </li>
        <li>
          The geographic location of your mobile device if you use certain
          features of our apps or portals
        </li>
        <li>
          Payment information (including payment via card and its details or
          online payment services number and invoicing address) and financial
          information (such as bank account numbers). The same is handled with a
          reputed payment gateway provider which complies with necessary data
          privacy obligations and RBI’s guidelines. ESGFIT.IN does not take any
          liability for such information. In case of any issues, the client
          needs to resolve it with the said payment gateway provider/bank.
        </li>
        <li>
          Tax identification number in circumstances in which you request any of
          our products or services for which this information is required, or in
          connection with specific promotions or prize draws
        </li>
        <li>
          Other personal information may be provided to us to obtain ESGFIT.IN
          services
        </li>
        <br />
        <Typography>
          The ESGFIT.IN is exempted from this notice. To reflect local customs
          and regulatory standards, our practices may be varied throughout the
          areas in which we operate.
        </Typography>
      </Box>
    ),
  },
  {
    desc: "We also receive Clients’ personal information from our employees to perform services. We obtain personal information from the third parties, including public databases, social media platforms, or from the data collectors, analytics or marketing providers. In addition to that, when you visit our website, use our apps, or interact with the ESGFIT.IN related tools, widgets, or plug-ins, we might collect certain information by automated means, such as cookies or web beacons. The information we collect includes IP address, unique device identifier number, browser characteristics, operating systems, language preferences, referring URLs, information on actions taken, and date and time of the activity.",
  },
  {
    desc: "A “cookie” is a text file that the websites send to a visitor’s system or other internet-connected devices to exclusively identify the visitor’s browser or store information or settings in the browser. We may link certain data we have collected through automated means, such as browser information we have obtained about any user, to let us know, for example, whether you have opened an email we sent you. We might as well use third-party analytics tools that collect information about the visitor traffic on our websites or apps. Your browser may tell you how to be notified when you receive certain cookies. Please note, however, that with cookies, you may not be able to use all the features of our websites or apps. In addition, we and others (such as our advertising networks) may collect personal information about our visitors’ online activities over time and across third-party websites whilst using our websites and apps.",
  },
  {
    desc: "We collect session analytics information when you visit our websites and applications to help us understand our client’s online activities, browsing behaviour, click patterns, and the pages you visited. This information is also helpful to our experience teams so they can design better experiences for you.",
  },
  {
    desc: "The providers of third-party apps, tools, widgets, and plug-ins on our websites and apps may also be generated by  automated means to collect information regarding your communications with these features. This information is subject to the privacy policies or notices of these providers.",
  },
  {
    title: "How we use the information we obtain;",
    desc: (
      <Box>
        <li>Provide all sorts of services you request</li>
        <li>
          To keep you updated about ESG-related norms, any amendments,
          sustainability reports, the ratio of ESG adaptability all across the
          globe, and other such information.
        </li>
        <li> To process and collect payments</li>
        <li>
          Acknowledge you to have ESG’s best practices, our performance as a
          tool, goals we intend to acquire, and our key features
        </li>
        <li>
          Communicate about, and administer your participation in special events
          or surveys.
        </li>
        <li>
          Enable you to post on blogs and interact with ESGFIT.IN through social
          media.
        </li>
        <li>Send information to your contacts if you ask us to do so.</li>
        <li>
          Perform data analyses (including market and consumer search and
          analytics, trend analysis and profiling, financial analysis, and
          anonymization of personal information)
        </li>
        <li>
          Protect against, identify and prevent fraud and other prohibited or
          illegal activity, claims, and other liabilities
        </li>
        <li>Comply with applicable legal requirements and our policies</li>
        <li>Establish, implement and secure legal related claims</li>
        <li>Monitor and report compliance issues</li>
        <br />
      </Box>
    ),
  },
  {
    title:
      "Furthermore, we use data obtained online via cookies, web beacons, and other automated means for purposes such as;",
    desc: (
      <Box>
        <li>Provide all sorts of services you request</li>
        <li>Enhancing our users’ visits to our websites and apps.</li>
        <li>
          Delivering content (including advertising) tailored to our users’
          interests and the way they navigate our websites and apps, and
        </li>
        <li>
          Managing our business, to provide you with content that may interest
          you, we may supplement data we acquire through automatic means with
          information about your location (such as your postcode).
        </li>
        <li>
          We also use this data to diagnose technical and service issues,
          administer our websites and applications, identify users of our
          websites and apps, and compile demographic data on our users.
        </li>
      </Box>
    ),
  },
  {
    title: "Log Files",
    desc: "ESG FIT follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and are a part of hosting services’ analytics. The information collected by log files includes internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any personally identifiable information. Instead, the report aims to analyze trends, administer the site, track users’ movement on the website, and gather demographic information.",
  },
  {
    title: "Internet-based Advertising",
    desc: "We may gather information about your browsing habits on our websites and apps to target the relevant advertising about products and services to your specific interests. This part of our Privacy Notice outlines how to implement your choices and offers extra details.",
  },
  {
    desc: "Because we cooperate in advertising networks, you may see certain ads on other websites. We may target our advertising to users using ad networks based on demographics, interests, and context. These networks acquire information through automated mechanisms, such as cookies, web server logs, and web beacons to follow your online actions over time. The networks use this information to display adverts personalized to your specific interests. In addition, our ad networks may collect information about your visits to websites that participate in the relevant advertising networks, such as the pages or advertisements you view and the actions you take on the websites. This data collection occurs both on our websites and third-party websites that participate in the ad networks. This process also helps us track the effectiveness of our marketing efforts.",
  },
  {
    title: "Information We Share",
    desc: "Except as specified in this Privacy Notice, we do not sell or otherwise disclose personal information about you. We share essential information with third parties such as commutators, stakeholders, and third-party payers and offer recipients advisory services. Personal information is also shared with third parties who execute services on our behalf and follow our directions. We have not given these third parties permission to use or disclose the information unless it is essential for them to perform services on our behalf or to comply with legal requirements.",
  },
  {
    desc: "We also may share the personal information we obtain with our affiliates, stakeholders, and joint marketing partners. These entities, which collectively are referred to here as the “ESGFIT.IN Business Partners”, may use the information described in this Privacy Notice. We may share physical location data with our ESGFIT.IN Business Partners and other third parties to, for example, enhance location-based services and develop accurate and up-to-date maps. In addition, except as described below, unless you object, we may share other personal information with third parties who are not ESGFIT.IN Business Partners for those parties’ purposes, such as to offer products or services that may interest you.",
  },
  {
    desc: "Information gathered through third-party apps, tools, widgets, and plug-ins is collected directly by the providers of these features (for example, information received through third-party login services). This communication is subject to the data policies of the feature providers and ESGFIT.IN is not responsible for the information activities of those holders.",
  },
  {
    title: "We may indeed disclose information about you",
    desc: (
      <Box>
        <li>
          If required to do so by law, regulation, or legal process (such as a
          court order or obtaining a warrant),
        </li>
        <li>
          In response to requests from government agencies, such as law
          enforcement authorities, or
        </li>
        <li>
          When we believe disclosure is necessary or appropriate to prevent
          physical harm or financial loss,
        </li>
        <li>
          In connection with an investigation of suspected or actual illegal
          activity. Suppose we transfer and use all or a portion of our business
          or assets. In that case, we reserve the right to transmit any
          information we hold about you (including in the event of a
          reorganization, dissolution, or liquidation).
        </li>
      </Box>
    ),
  },
  {
    title: "Third Party Privacy Policies",
    desc: "ESG FIT’s Privacy Policy does not apply to other advertisers or websites. Thus, we advise you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. For example, it may include their practices and instructions about opting out of certain options.",
  },
  {
    desc: "You can choose to disable cookies through your browser options. To know more detailed information about cookie management with specific web browsers, it can be found on the browsers’ respective websites.",
  },
  {
    title: "Options provided to You by the Website",
    desc: "We provide you with several options for interfacing with you and what data we collect about you. By clicking on the unsubscribe link in our marketing emails, adjusting your email preferences in your ESGFIT.IN.com profile, or contacting us as specified in the “Contact us” section on ESGFIT.IN.com, you can opt out of receiving any undesirable email communications from us. Furthermore, as required by law, you have the right to object to handling your sensitive data for legitimate reasons and without charge, and we will follow your preference in the future.",
  },
  {
    title: "Accessibility and Corrections",
    desc: (
      <Box>
        <li>
          You may obtain a copy of certain personal information we maintain
          about you, as well as update or correct inaccuracies in that
          information, through your ESGFIT.IN.com account, or
        </li>
        <li>
          You may have the right to access confidential information we acquire
          about you by reaching us in the “Contact Us” section of this Privacy
          Notice, subject to applicable law.
        </li>
        <br />
        <br />
        <Typography>
          Before providing you access to the information, we will take measures
          to authenticate your identity and/or may require you to submit
          additional information to assist in safeguarding your privacy and
          security. Furthermore, according to applicable legislation, if you
          believe that personal information we hold about you is erroneous, you
          may have the right to request that we rectify or modify the
          information by contacting us on our portal.
        </Typography>
      </Box>
    ),
  },
  {
    title: "Data Transfers",
    desc: "We may transmit the personal information we acquire about you to countries other than the one where it was collected initially. This is because those nations may not have the same data protection rules as the country where you gave the information. We will protect your personal information as detailed in this Privacy Notice and comply with relevant legislation when sending it to other countries. For the transfer of personal data between jurisdictions, we employ contractual protections.",
  },
  {
    title: "Updates to our Privacy Notice",
    desc: "This Privacy Notice may be modified from time to time to reflect changes in our information practices without previous notice to you. We will post a prominent message on our websites notifying you of any substantial changes to our Privacy Notice. In addition, we will specify when it was most recently updated at the top of the notice.",
  },
  {
    title: "Contact Us",
    desc: "If you have any queries or comments about this Privacy Notice or want us to update information we have about you or your preferences, please get in touch with us by dropping a request on our “Contact Us” section of the portal.",
  },
];
