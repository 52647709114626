import { Box, Typography } from "@mui/material";
import React from "react";
import Footer from "../../components/footer";
import TryEsg from "../../components/Common/TryEsg";

function TermsConditions() {
  return (
    <>
      <Box className="legal-bg">
        <Box className="overlay-legal" />
        <Typography
          variant="h1"
          color="white"
          position="relative"
          zIndex={3}
          marginTop="-30px"
        >
          Terms & Conditions
        </Typography>
      </Box>
      <Desc />
      <TryEsg />
      <Footer />
    </>
  );
}

export default TermsConditions;

const Desc = () => {
  return (
    <Box className="grid-container">
      <Typography variant="h4">Website Terms & Conditions</Typography>
      <br />
      {data.map((item, index) => (
        <Box key={index}>
          {item.title && (
            <>
              <li style={{ marginLeft: "-20px" }}>{item.title}</li>
              <br />
            </>
          )}
          <Typography>{item.desc} </Typography>
          <br />
        </Box>
      ))}
    </Box>
  );
};

const data = [
  {
    desc: "Please read the website’s (ESGFIT.IN) Terms and Conditions for vigilant website usage. The website’s terms of use shall standardize your access to and use of the website. The website shall be accessible only on the pre-requisite that you agree to the terms stated below. Do not access or use the website if you do not agree to any of the terms and conditions specified. By getting on board or using the website, you, and the entity you are authorized to signify your Agreement to be bound by the terms of use.",
  },
  {
    desc: "Your use of this site and your electronic acceptance of this Agreement signifies that you have read, understand, acknowledge and agree to be bound by this Agreement our Privacy policy. The terms “we”, “us” or “our” shall refer to Company. The terms “you”, “your”, “User” or “customer” shall refer to any individual or entity who accepts this Agreement, uses our site, has access or uses the Services. Nothing in this Agreement shall be deemed to confer any third-party rights or benefits.",
  },
  {
    title: "User Admissibility:",
    desc: " ESGFIT pledges the website and is accessible on the entities and people who have attained the age of legal majority and are proficient in entering into a legally obligated contract under the pertinent law. If  you do not align with this condition, you cannot use the website. The technology workflows and allied content are only for use on a subscription basis.",
  },
  {
    title: "Ambit of terms of use:",
    desc: "These terms of use lead to your usage of the website and all requisitions, software and services obtainable via website except to the degree that such services are the matter of a distinct agreement. Detailed terms and conditions may apply to specific services and other articles available via website. Any such service contract will go along with the good services or are listed in connotation in addition to that or via a hyperlink allied.",
  },
  {
    title: "Alterations:",
    desc: "ESGFIT.IN may re-draft and update these terms of use anytime. After the alterations of terms of use are incorporated, your continued usage of the website shall be deemed as acceptance of such changes. Any website norms may be altered, appended, updated, or deleted without any notification, at the sole inclination of ESGFIT.IN. ESGFIT.IN may also modify or implicate fees for any products, services, or conditional subscriptions provided through the website, at its sole preference. ESGFIT.IN may as well change or institute, at any time, the general practices and limitations vis-à-vis other ESGFIT.IN concerns and services at its sole discretion. For any customer-specific customizations, the same would go through additional costs change request processes deemed appropriate by ESGFIT.IN. There would be extra costs levied on cloud usage, number of users and/or number of units if the customer exceeds the monthly subscription quota which had been subscribed. The customer is required to pay the same.",
  },
  {
    title: "IN service Terms and Conditions:",
    desc: "The services terms and conditions of the ESGFIT.IN applicable to the ESG-related norms and practices provided by this website that oversees your use of such ESGFIT.IN guidelines and associated procedures obtained via this website, in tallying to any other terms and conditions that may apply to such practices as furnished in this website’s terms of usage and service Agreement (s). The ESGFIT.IN service terms and conditions are hereby assimilated into these terms of use. All credentials herein to the terms of use shall be deemed to add the website terms and conditions to the degree of applicability. ",
  },
  {
    title: "Details and data processed through the website and services:",
    desc: "These terms and usage govern your information compliance through the website and the services. You signify and certify that any information you provide through the website or the services are and shall remain valid and comprehensive and that you will preserve and update such details as required.",
  },
  {
    desc: "Concerning any individual whose personal information is given by you to this website and the services, you denote to this website that you can provide such information and that you have submitted all required notifications and acquired all obligatory consents for proclaiming such details anticipated by the services you are using.",
  },
  {
    title: "Data Transfer:",
    desc: "If you are visiting this site from a country other than the country in which our servers are located, your communications with us may result in the transfer of information across international boundaries. By visiting this site and communicating electronically with us, you consent to such transfers.",
  },
  {
    title: "Permit and Proprietorship:",
    desc: "All or any Intellectual Property Rights (IPR) or rights reserved to this website and even its substances are the sole property of ESGFIT.IN, it associates with or to its third parties. The contents are protected by the copyright issued by the origin of authorship of all the contents of ESGFIT.IN portal. Constituents of the website is preserved by trade dress, trade secret, unfair contentions, and other laws and might not be duplicated or counterfeited in any sort as a whole or as a part. All modified icons, graphics, and other items on the website are the trademarks, service marks, or trade dress of ESGFIT.IN the rights and license to use such marks and might not be used or impeded in any mode without the explicit written consent of ESGFIT.IN. Except as otherwise specifically directed by these terms of use, you may not copy, make alterations, amendments, replicate, lease, loan sell or led down imitative works from, convey, upload and/or dispense the IP rights of the website in any way without taking a prior written permission from ESGFIT.IN or that of an appropriate third party. Except as explicitly given herein, ESGFIT.IN does not grant the permit or any right, expressly or impliedly to the Intellectual property.",
  },
  {
    desc: "ESGFIT.IN at this moment permits you a limited, non-transferable, non-sublicensable, revocable, private license to",
  },
  {
    title:
      "Admit and custom only the website, content, and services only in the manner given by ESGFIT.IN system",
  },
  {
    title:
      "Network services are offered within the website only in the manner set forth by ESGFIT.IN.",
    desc: "Except for this license limitation, ESGFIT.IN does not deliver any interest in or to the ESGFIT.IN Systems, details and the data available via the system, facilities, matters, website, or any property belonging to us by permitting you to access the website. Except to the extent demanded by the law or as explicitly given herein, none of the information and/or contents may be replicated, amended, republished, altered, transcript into any other language or binary (computer) language, re-transmitted in any manner, resold or redistributed without the prior written accord of ESGFIT.IN. You may not sell, alter, transfer, amend, replicate, reproduce, display, offer, publicly perform, distribute, re-transmit or otherwise use the content in any way unless expressly permitted to do so by ESGFIT.IN.",
  },
  {
    title: "Indemnity",
    desc: "You agree to protect, defend, indemnify and hold harmless ESGFIT.IN and its officers, directors, employees, agents from and against any claims, demands, costs, expenses, losses, liabilities, and damages of every kind and nature (including, without limitation, reasonable attorneys’ fees) imposed upon or incurred by Company directly or indirectly arising from (i) your use of and access to this site; (ii) your violation of any provision of this Agreement or the policies or agreements which are incorporated herein; and/or (iii) your violation of any third-party right, including without limitation any intellectual property or other proprietary rights. The indemnification obligations under this section shall survive any termination or expiration of this Agreement or your use of this site or the Services found at this site.",
  },
  {
    title: "Limitations upon the usage of the website:",
    desc: (
      <Box>
        <li>
          You shall not camouflage the source of material conveyed through the
          Web Site.
        </li>
        <li>
          You will not place any misleading, inappropriate and fabricated
          information upon the website.
        </li>
        <li>
          You will not access any applications, service, information or software
          available via the website in any manner if not explicitly permitted by
          ESGFIT.IN.
        </li>
        <li>
          You will not input or upload to the Web Site any data that may contain
          viruses, Trojan horses, worms, time bombs or other computer
          programming procedures that are envisioned to mutilation, obstruct
          with, intercept or confiscate any system, the Web Site or Data or that
          oversteps the Intellectual Property rights of another.
        </li>
        <li>
          Specific areas of the Web Site are constrained just to clients of
          ESGFIT.IN.
        </li>
        <li>
          You may not use or enter the Web Site or the ESGFIT.IN Systems in any
          way that, in its verdict, unfavourably disturbs the performance of our
          Systems or the Web Site or interferes with the capability of
          authorised parties to admission to our Systems, Services or the Web
          Site.
        </li>
        <li>
          You may not frame, edge or exploit outlining procedures to enfold any
          quota or aspect of the content or the data, without the express
          written consent of ESGFIT.IN.
        </li>
        <br />
        <Typography>
          In case you are using any data or using any updates from our page
          without an explicit written consent, it will lead to a criminal
          liability upon you. No commercial usage of content or technology
          workflows is allowed . If at any point of time , ESGFIT.IN feels that
          fair usage is exceeded by the client , the access would be revoked and
          no refunds for the same. The decision of ESGFIT.IN is final in such
          cases.
        </Typography>
      </Box>
    ),
  },
  {
    title: "URLS or Links:",
  },
  {
    title: "Outbound Links: ",
    desc: "The website may contain links to third-party websites and source links. These Linked sites were provided solely as a suitability to you and not a commendation by ESGFIT.IN of the content of such Linked Sites. ESGFIT.IN makes no depictions or warranties concerning the precision, exactitude, presentation, or eminence of any content, service, software, or application found on the Linked Sites. ESGFIT.IN shall not be accountable for the availability of the linked sites or the content or goings-on of such Sites. If you decide to access Linked Sites, you do so at your own risk. In addition, your use of Associated Sites is subject to any appropriate strategies and terms and conditions of use, counting but not restricted to the Linked Site’s discretion policy.",
  },
  {
    title: "Inbound Links:",
    desc: "Linking to any Web Site page through a plain text link is stringently forbidden in the absence of a separate linkage agreement with ESGFIT.IN. Any website or any page available therein is prohibited from",
  },
  {
    desc: (
      <Typography>
        (a) Replicating Content,
        <br />
        <br />
        (b) Using a browser or border environment around the content
        <br />
        <br />
        (c) Implying in any manner that ESGFIT.IN or any of its associates
        endorse it or its products
        <br />
        <br />
        (d) Misrepresenting any state of facts, including its relationship with
        ESGFIT.IN or any of the ESGFIT.IN affiliates,
        <br />
        <br />
        (e) Presenting false information about ESGFIT.IN practices or services,
        and
        <br />
        <br />
        (f) Using any logo or mark of ESGFIT.IN or any of its affiliates,
        without express written permission from ESGFIT.IN.
      </Typography>
    ),
  },
  {
    title: "Capitulations:",
    desc: (
      <Box>
        <Typography>
          ESGFIT.IN does not accept ideas, concepts or techniques for new
          services or products through the Web Site (“Comments”). If such
          Comments are received, you concede that
        </Typography>
        <br />
        <br />
        <Typography>
          (a) They will not be considered confidential or proprietary,
          <br />
          <br />
          (b) ESGFIT.IN and its affiliates are under no obligation to keep such
          information confidential, and
          <br />
          <br />
          (c) ESGFIT.IN will have an unrestricted, irrevocable, worldwide,
          royalty-free right to use, communicate, reproduce, publish, display,
          distribute and exploit such comments in any manner it chooses.
        </Typography>
      </Box>
    ),
  },
  {
    title: "Disclaimer of Warranties:",
    desc: "ESGFIT.IN makes no illustrations about the consequences to be attained from using the web site, the ESGFIT.IN systems, the services, the data or the content. The use of same is at your own risk. The web site, the ESGFIT.IN systems, the information, the services and the content are provided on an “as is” basis. ESGFIT.IN, its licensor, and its stakeholders, to the wide scope legalized by law, renounce all warranties, either express or implied, statutory or otherwise, including but not limited to, the implied warranties of merchantability, non-infringement of third party rights, and fitness for a particular purpose. ESGFIT.IN and its affiliates, licensors and stakeholders make no representations or warranties concerning the accuracy, completeness, security or timeliness of the content, information or services provided on or through the use of the web site or the ESGFIT.IN systems. No information obtained by you from the web site shall create any warranty not expressly stated by ESGFIT.IN in these terms of use.",
  },
  {
    desc: "Some dominions do not allow restrictions on implicit warranty, so the restrictions and prohibitions in this section may not apply to you. If you are dealing as a consumer, your statutory rights that cannot be waived shall not be affected by these provisions. You agree and acknowledge that the limitations and exclusions of liability and warranty provided in these terms of use are fair and reasonable.",
  },
  {
    title: "Limitation of Liability:",
    desc: "To the extent permitted by applicable law and to the extent that ESGFIT.IN is otherwise found to be responsible for any damages, ESGFIT.IN shall be liable for actual damages only. To the extent permitted by law, in no event shall ESGFIT.IN, its affiliates, licensors, stakeholders or any third parties mentioned at the web site be liable for any incidental, indirect, exemplary, punitive and/or consequential damages, lost profits, and/or damages resulting from lost data or business disruption resulting from the use of and/or inability to use the web site, the ESGFIT.IN systems, data, services, or the content whether based on warranty, contract, tort, delict, or any other legal foundation, and whether or not ESGFIT.IN is advised of the possibility of such damages. To the extent permitted by law, the remedies stated for you in these terms of use are exclusive and are limited to those expressly provided for herein.",
  },
  {
    title: "Cancellation & Refunds:",
    desc: "No refunds on cancellation otherwise as required by any law would be the effective policy as the buy decision has been done after fair diligence & evaluation by the customer after reviewing sufficient information and demo trials, if any availed. Cancellation based on technical issues – has to be based on query generated on CRM. Thereafter a sufficient cure period would be mutually decided and even after the same if the query is unresolved, the pro-rata refund would be done by issuing credits of equivalent amount of unused subscription period to avail other services of ESGFIT.IN or its partners .",
  },
  {
    title: "Jurisdiction:",
    desc: "To its complete extent permitted by law, you hereby expressly agree that any proceedings arising out of or relating to your use of the web site, the ESGFIT.IN systems, information, services and content shall be instituted in the court of Delhi, India and you expressly waive any objection that you may have now or hereafter to the venue of or to the jurisdiction over any such proceeding. You agree that any claim or cause of action arising from or related to your use of the web site, the ESGFIT.IN systems, information, services and/or content must be filed within one (1) year from the date on which such claim or cause of action arose.",
  },
  {
    title: "Broad-spectrum:",
    desc: "You may not assign these Terms of Use or any of your interests, rights, or responsibilities. Suppose any provision of these Terms of Use shall be found to be invalid by any court having competent jurisdiction. In that case, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms of Use, which shall remain in full force and effect. No waiver of these Terms of Use shall be deemed a further or continuing waiver of such term or condition or any other term or condition.",
  },
  {
    title: "Written Deed:",
    desc: "You may preserve these Terms of Use in written form by printing them for your archives, and you relinquish any other requirement for these Terms of Use to be verified by means of a written document.",
  },
  {
    title: "Comprehensive Contract:",
    desc: "Except as expressly provided for under a separate licence, service or other written Agreement between you and ESGFIT.IN or in the applicable ESGFIT.IN rate and service guide or ESGFIT.IN tariff, these terms of use constitute the entire Agreement between you and ESGFIT.IN concerning the use of the website, the ESGFIT.IN systems, and any software or service, information and content contained therein, and supersede all discussions, communications, conversations and agreements concerning the subject matter hereof.",
  },
  {
    title: "Customer Service:",
    desc: "Questions or comments regarding the Web Site may be submitted to ESGFIT.IN Customer Service.",
  },
];
