import { Box, Grid, Typography } from "@mui/material";
import React from "react";

function Listing() {
  return (
    <Box className="grey-bg-graph1" data-aos="fade-up" data-aos-delay="600">
      <Box className="grid-container">
        <Typography variant="h4" className="title-depth">
          Centric to Investors
        </Typography>
        <Typography>
          Fairness, Integrity & Transparency is key for any investor on ESG.
          ESGFIT.in gives the investment community visible BI on various ESG
          parameters and helps them for their UNPRI, GSA etc. reporting
          commitments on investee companies.
        </Typography>
        <br />
        <Typography variant="h5">DIY for Popular ESG Standards</Typography>
        <br />
        <li> UN Sustainable Development Goals</li>
        <li>GRI</li>
        <li>
          BRSR-Business Responsibility & Sustainability Reporting (SEBI-India)
        </li>
        <li>Carbon Disclosure Project</li>
        <li>IFC</li>
        <br />
        <Typography variant="h5">
          Proprietary Governance review module BBBE (Boardroom, Business,
          Benchmarking & Evaluation)
        </Typography>
        <br />
        <Typography>
          More than 500 review data points in governance being researched as per
          Indian Stock Exchanges, NYSE, Singapore & Australia. A must for the G
          Part in ESG.
        </Typography>
        <br />
        <Typography variant="h4">
          DIFM for other ESG Performance Standards
        </Typography>

        <List />
      </Box>
    </Box>
  );
}

export default Listing;

const List = () => {
  return (
    <Grid container spacing={2} className="listing-wrapper">
      <Grid item md={4}>
        <li>Right to organize and collective bargaining</li>
        <li>Abolition of Forced Labour Convention, 1957</li>
        <li>
          Act on Corporate Due Diligence Obligations for the Prevention of Human
          Rights Violations in Supply Chains
        </li>
        <li>BVCA: Guide to Responsible Investment</li>
        <li>BVCA: Walker Guidelines (on GP and portfolio company reporting)</li>
        <li>
          CFA Institute publication Environmental, Social and Governance Factors
          at Listed Companies: A Manual for Investors
        </li>
        <li>Convention on International Trade in Endangered Species</li>
        <li>Copenhagen Accord</li>
        <li>
          Development finance institutions (DFIs): a variety of DFIs have ESG
          policies for their private equity investments
        </li>
        <li>DFI Toolkit on Corporate Governance</li>
        <li>Discrimination (Employment and Occupation) Convention, 1958</li>
        <li>
          EDFI Principles for Responsible Financing and Guidelines for Fund
          Investments
        </li>
        <li>
          EDFI Principles for Responsible Financing of Sustainable Development
        </li>
        <li>Equal Remuneration Convention, 1951</li>
        <li>Equator Principles</li>
        <li>EVCA: Corporate Governance Guidelines</li>
        <li>EVCA: Governing Principles</li>
        <li>EVCA: Reporting Guidelines</li>
        <li>Extractive Industries Transparency Initiative</li>
        <li>Financial Action Task Force</li>
        <li>
          Freedom of Association and Protection of the Right to Organise
          Convention, 1948
        </li>
        <li>
          Global Reporting Initiative (GRI) Sustainability Reporting Guidelines
        </li>
        <li>
          Good manufacturing practices in the production of food and
          pharmaceuticals
        </li>
      </Grid>
      <Grid item md={4}>
        <li>IIGCC: A Guide on Climate Change for Private Equity Investors</li>
        <li>ILO Declaration on Fundamental Principles and Rights at Work</li>
        <li>ILO’s Fundamental Principles and Rights at Work Branch</li>
        <li>ILPA: Private Equity Principles 2.0</li>
        <li>
          International Accounting Standards Board and the International
          Financial Reporting Standards
        </li>
        <li>
          International Private Equity and Venture Capital Valuation Guidelines
        </li>
        <li>IOSCO: Private Equity Conflicts of Interest</li>
        <li>
          ISO 26000 Guidance on Social Responsibility (tables A.1 and A.2, pages
          81-90)
        </li>
        <li>Kyoto Protocol</li>
        <li>Minimum Age Convention, 1973</li>
        <li>Money Laundering, Terrorist and Transfer of Funds Act (UK)</li>
        <li>Montreal Protocol</li>
        <li>Occupational Health and Safety Assessment Series 18000</li>
        <li>OCED Principles of Corporate Governance</li>
        <li>OECD Anti-Bribery Convention</li>
        <li>OECD Guidelines for Multinational Enterprises</li>
        <li>OECD Principles of Corporate Governance</li>
        <li>PEGCC: Guidelines for Responsible Investment</li>
        <li>Proceeds of Crime Act 2002</li>
        <li>Rotterdam Convention</li>
        <li>SASB</li>
        <li>Stockholm Convention</li>
        <li>
          Sustainability‐related disclosures in the financial services sector
        </li>
        <li>Task force on climate related financial disclosures</li>
      </Grid>
      <Grid item md={4}>
        <li>
          The Convention on International Trade in Endangered Species of Wild
          Fauna and Flora (CITES)
        </li>
        <li>The Rio Conventions</li>
        <li>
          The UN Environment Programme’s Industry Report Cards on Environmental
          and Social Responsibility The Universal Declaration of Human Rights
        </li>
        <li>
          Transparency International’s Corruption Perceptions Index (2009)
        </li>
        <li>UK Proceeds of Crime Act and UK Bribery Act</li>
        <li>UN Anti-corruption toolkit</li>
        <li>UN Framework Convention on Climate Change</li>
        <li>UN Global Compact</li>
        <li>United Nations Conference on the Human Environment</li>
        <li>United Nations Convention against Corruption</li>
        <li>US Foreign Corrupt Practices Act</li>
        <li>US Private Equity Council Responsible Investment Guidelines</li>
        <li>Walker Report</li>
        <li>Worst Forms of Child Labour Convention, 1999 </li>
        <li>And many others…</li>
      </Grid>
    </Grid>
  );
};
