import React from "react";
import { Box, Typography } from "@mui/material";
import { FaRegObjectGroup } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import { FaAssistiveListeningSystems } from "react-icons/fa";
import { GoDeviceMobile } from "react-icons/go";
import CommonDivider from "../Common/CommonDivider";

function Cards() {
  return (
    <>
      <Typography
        variant="h2"
        className="title"
        marginTop="20px"
      >
        We Help You Build Your Own
        <br />
        ESG Success Story
      </Typography>
      <Box className="cards-section">
        {Arr.map((item, index) => (
          <Box key={index} className="card-wrapper">
            {item.image}
            <Box className="card">
              {item.icon}
              <Typography gutterBottom variant="h5" color="#fff">
                {item.title}
              </Typography>
              <br />
              <Typography variant="body2" color="#fff">
                {item.desc}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
      <br />
      <br />
      <CommonDivider />
    </>
  );
}

export default Cards;

const Arr = [
  {
    icon: <FaRegObjectGroup />,
    title: "Centric to Investors",
    desc: "Fairness, Integrity & Transparency is key for any investor on ESG. ESGFIT.in gives the investment community visible BI on various ESG parameters and helps them for their UNPRI, GSA etc.",
    image: <img src={require("../../img/Card/card1.jpeg")} alt="" />,
  },
  {
    icon: <FaRegHeart />,
    title: "Proprietary Governance Review Module (BBBE)",
    desc: "Boardroom, Business, Benchmarking & Evaluation - More than 500 review data points in governance being researched as per Indian Stock Exchanges, NYSE etc.",
    image: <img src={require("../../img/Card/card2.jpeg")} alt="" />,
  },
  {
    icon: <FaAssistiveListeningSystems />,
    title: "DIY (Do It Yourself) & DIFM (Do It for Me) Methodology",
    desc: "In line with digital transformation, our compliance management system is developed on combination of digital programming and IT ops.",
    image: <img src={require("../../img/Card/card3.jpeg")} alt="" />,
  },
  {
    icon: <GoDeviceMobile />,
    title: "More Power to You.",
    desc: "We further assist with identifying risks and improve collaboration and communication across your teams with intuitive graphical dashboards and charts that provide critical real-time representation into compliance processes.",
    image: <img src={require("../../img/Card/card4.jpeg")} alt="" />,
  },
];
