import React from "react";
import { Box, Typography } from "@mui/material";
import CommonFAQ from "../Common/CommonFAQ";

function Faq() {
  return (
    <div>
      <br />
      <Typography variant="h2" className="title">
        Frequently Asked Questions
      </Typography>
      <Box className="Accordian-section">
        <CommonFAQ data={data} />
      </Box>
      <br />
    </div>
  );
}

export default Faq;

const data = [
  {
    title: "What would be the essential package cost?",
    desc: "1 USD per day for either of the Platforms for companies < 1 million USD in revenue. We are committed to scaling up small companies to Unicorns! ESG does matter.",
  },
  {
    title: "Would I get a demo done on filling out the form?",
    desc: "Our team would connect and have a no-obligation demo with you",
  },
  {
    title: "What if I have revenue of more than USD 1 million revenue?",
    desc: "Customized pricing, which would be lesser than 1 USD per day!",
  },
  {
    title: "What are the payment modes?",
    desc: "Both Online and Offline options are available. Our onboarding team will assist with the same.",
  },
  {
    title: "Can I pay Monthly?",
    desc: "Based on customized pricing, a payment frequency can be set for you.",
  },
];
