import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import authorship from "../../../../img/Stats/authorship.png";
import magazine from "../../../../img/Stats/magazine.png";
import notepad from "../../../../img/Stats/notepad.png";
import register from "../../../../img/Stats/register.png";
import startrophy from "../../../../img/Stats/startrophy.png";

function Statistics() {
  return (
    <Box className="stat-bg-wrapper">
      <Box className="grid-container">
        <Typography variant="h4" className="title" color="white">
          KEY MARATHON STATISTICS
        </Typography>
        <Grid container spacing={2} border="1px solid white" padding={2}>
          {data.map((item, index) => (
            <Grid item xs={6} md={2.4} display="flex" flexGrow={1}>
              <Box className="stat-details" sx={{ paddingY: 5 }}>
                <img src={item.src} alt="" />
                <Typography variant="h5">{item.title} </Typography>
                <Typography>{item.desc} </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default Statistics;

const data = [
  {
    src: register,
    title: "OVER 700 REGISTRATIONS",
    desc: "Requests Received",
  },
  {
    src: notepad,
    title: "OVER 100 SHORTLISTED",
    desc: "Contenders",
  },
  {
    src: startrophy,
    title: "30 INTERNSHIPS",
    desc: "Offers to Winners",
  },
  {
    src: magazine,
    title: "MAGAZINE SUBSCRIPTIONS",
    desc: "Annual Subscription of Lex Witness Magazine for Winners",
  },
  {
    src: authorship,
    title: "AUTHORSHIP OPPORTUNITIES",
    desc: "Co-Authorship Opportunity with the Firm for Winners for Lex Witness Magazine",
  },
];
