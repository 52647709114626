import React from "react";
import { Box, Typography } from "@mui/material";
import android from "../../img/android.png";
import creditcard from "../../img/creditcard.png";
import circle from "../../img/circle.png";
import Typewriter from "typewriter-effect";

function ExpertiseBanner() {
  return (
    <Box className="banner-wrapper">
      <br />
      <br />
      <br />
      <Typography variant="h1" className="exper-title">
        Begin Your ESG Journey Today!
        <br />
        <span style={{ display: "flex", justifyContent: "center", flexWrap:"wrap" }}>
          ESG Ensures&nbsp;
          <span className="text-green">
            <Typewriter
              options={{
                strings: [
                  "Legal Adherence",
                  "Global Compatability",
                  "Profitability",
                  "Proactive Measures",
                ],
                autoStart: true,
                loop: true,
                delay: 25,
                deleteSpeed: 25,
              }}
            />
          </span>
        </span>
      </Typography>
      <Box className="images-wrapper">
        <div className="circle-img">
          <img src={circle} alt="" />
        </div>
        <div className="android-img" data-aos="zoom-in" >
          <img src={android} alt="" />
        </div>
        <div className="credit-img" data-aos="bounce-around" data-aos-delay="500">
          <img src={creditcard} alt="" />
        </div>
      </Box>
    </Box>
  );
}

export default ExpertiseBanner;
