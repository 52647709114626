import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { BsPlayCircle } from "react-icons/bs";
import { SlSocialSpotify } from "react-icons/sl";
import { CgFacebook } from "react-icons/cg";
import { IoLogoTwitter } from "react-icons/io";
import { FaLinkedinIn } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";

import dd from "../../../img/D&D.png";
import people2 from "../../../img/People/people2.jpeg";
import people7 from "../../../img/People/people7.jpeg";
import people8 from "../../../img/People/people8.jpeg";
import people9 from "../../../img/People/people9.jpeg";
import people10 from "../../../img/People/people10.jpeg";
import people11 from "../../../img/People/people11.jpeg";
import people12 from "../../../img/People/people12.jpeg";

function AboutPodcasts() {
  return (
    <Box className="grid-container">
      <Grid container spacing={2}>
        {data.map((item, index) => (
          <Grid item xs={12} md={6} display="flex" flexGrow="1">
            <Box className="podcast-details-wrapper">
              <Box className="title-bar">{item.embeded?item.embeded:"Temp"}</Box>
              <Box className="people-details">
                <Box className="details-div">
                  <img src={item.src} alt="" />
                  <Typography variant="body3">{item.name}</Typography>
                  <Typography variant="subtitle5">{item.details}</Typography>
                </Box>
                <Box className="details-div">
                  <img src={people2} alt="" />
                  <Typography variant="body3">Sonal Verma</Typography>
                  <Typography variant="subtitle5">
                    Partner - ESG and Global Leader, Dhir & Dhir Associates
                  </Typography>
                </Box>
              </Box>
              <Box className="text-bottom">
                <p style={{ textTransform: "uppercase" }}>{item.title}</p>
                <div className="subdetails">{item.desc}</div>
                <br />
                <Box className="social-media-icon">
                  <span className="icon-wrapper facebook">
                    <CgFacebook />
                  </span>
                  <span className="icon-wrapper twitter">
                    <IoLogoTwitter />
                  </span>
                  <span className="icon-wrapper linkdin">
                    <FaLinkedinIn />
                  </span>
                  <span className="icon-wrapper whatsapp">
                    <FaWhatsapp />
                  </span>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
        <Grid item></Grid>
      </Grid>
    </Box>
  );
}

//  <Box>
//                   <img src={dd} alt="" />
//                 </Box>
//                 <Box className="text-title">
//                   <Typography variant="h5" textTransform="capitalize">
//                     {item.title}
//                   </Typography>
//                   <Box className="subtitle-area">
//                     <Box className="preview-text">PREVIEW</Box>
//                     <Box className="date-name">
//                       {item.date} Dhir & Dhir Associates
//                     </Box>
//                   </Box>
//                 </Box>
//                 <Box className="icon-div">
//                   <Box>
//                     <BsPlayCircle />
//                   </Box>
//                   <Box display="flex" gap="5px">
//                     <BsThreeDots />
//                     <SlSocialSpotify />
//                   </Box>
//                 </Box>

export default AboutPodcasts;

const data = [
  {
    embeded: (
      <iframe
        src="https://open.spotify.com/embed/episode/5pzVgzpvzv4gfSdcxenEQ3?utm_source=generator&theme=0"
        width="100%"
        height="152"
        loading="lazy"
        style={{ border: "none" }}
      ></iframe>
    ),
    src: people7,
    title:
      "Elevating The Sense Of Belonging - Diversity, Equality & Inclusion For All",
    date: "Aug 2",
    name: "Roma Balwani",
    details: "CEO & Brand Custodian, Indian Deaf Cricket Association",
    desc: "The 6th Podcast of ESG Decibel series with Roma Balwani is a take on the current status of DE&I in the corporate world and ESG Transformation in companies. Through her talk, Ms. Balwani has been kind enough to share her personal journey of transitioning to key leadership roles at some of the leading corporate houses in the country. She talks about how there is a need for change in the thought processes and how initiatives focused on promoting diverse representation are required to champion the spirit of inclusion and equality. Ms. Balwani shares how collaborative efforts can help change the narrative and thrive towards a diverse ecosystem. She also illustrates the ESG Scoring and transformation stories in India Inc! Join us to listen to Ms. Balwani share her thoughts on how DE&I is a non-negotiable approach! ESG Transformation is happening.",
  },
  {
    embeded: (
      <iframe
        src="https://open.spotify.com/embed/episode/6apMBkjbMwhXglyCMlTjuH?utm_source=generator&theme=0"
        width="100%"
        height="152"
        loading="lazy"
        style={{ border: "none" }}
      ></iframe>
    ),
    src: people8,
    title: "Demistifying the ESG Roots",
    date: "May 8",
    name: "Mr. Shailesh Haribhakti",
    details: "Chairman Shailesh Haribhakti & Associates",
    desc: "The 5th Podcast of ESG Decibels with Mr. Shailesh Haribhakti has taken us down the path of understanding the origin of ESG. Mr. Haribhakti has magnificently given us an analogy of the history of ESG and the origin of our universe. He has described in immaculate detail the various forces and factors regarding the evolution of ESG. Mr. Haribhakti has then wonderfully spoken about the 17 SDGs and their importance and integration into ESG. He has then reiterated the importance of mindset change as a vital force for ESG and its stakeholders. Mr. Haribhakti is passionately concerned about the environment and emphasizes the need to save our planet. He has also spoken about the tone from the top is critical for the Board of Directors to manage better and encourage ESG and about ESG ratings being a differentiator for the companies that are genuinely committed to ESG.",
  },
  {
    embeded: (
      <iframe
        src="https://open.spotify.com/embed/episode/6NuWFusbCYdYhcES5rZQjT?utm_source=generator&theme=0"
        width="100%"
        height="152"
        loading="lazy"
        style={{ border: "none" }}
      ></iframe>
    ),
    src: people9,
    title: "Sustainability & Fashion - Key ESG Boosters",
    date: "March 22",
    name: "Nivedita Saboo",
    details:
      "CEO & Brand Custodian, Indian Deaf Cricket AssociationFounder Nivedita Saboo Couture",
    desc: "Here’s the 4th Episode of the ESG Decibels Series titled Sustainability & Fashion – Key ESG Boosters. Our special guest speaker today is Nivedita Saboo – an ardent advocate of adaptive, inclusive, and sustainable fashion, Nivedita has showcased at London Fashion Week, Paris Fashion Week, Milan, Hong-Kong, Seoul, Colombo Fashion Week, and major cities across India; Designer to Warner brothers – the Wonder Woman India enterprise, numerous corporates, institutions, policymakers, media barons and influencers globally, Nivedita Saboo has marked her 18 exciting years in the industry.",
  },
  {
    embeded: (
      <iframe
        src="https://open.spotify.com/embed/episode/5A9Br2arDG8aHKsqTYirqn?utm_source=generator&theme=0"
        width="100%"
        height="152"
        loading="lazy"
        style={{ border: "none" }}
      ></iframe>
    ),
    src: people10,
    title: "ESG & DIRECTORS - AN INTERPLAY OF ROLES",
    date: "Jan 20",
    name: "Manoj K. Raut",
    details: "CEO and Secretary-General, Institute of Directors, India",
    desc: "Going LIVE with the 3rd episode in the ESG Decibels Series titled as ESG & Directors – An Interplay of Roles. We demystify how Directors have accepted ESG as a concept and embraced it. Manoj K. Raut, CEO and Secretary-General, Institute of Directors, India joins us in this enlightening conversation. He believes that sustainability is the future and that there is a need for R&D in India. He also believes that India has started the journey and is on the right path to achieve wonders when it comes to ESG. Raut joined Institute of Directors, India as a Management Trainee in the year 2000 and later on, in the year 2010, he was elevated as CEO of the Institute of Directors, India and also as Secretary-General. Currently he is also on a few not-for-profit Boards including on the boards of World Environment Foundation and International Academy of Law besides the Editor of Director Today – A Monthly Journal of IOD.",
  },
  {
    embeded: (
      <iframe
        src="https://open.spotify.com/embed/episode/0VEVUjSzB5Xf5t7QPZLF5r?utm_source=generator&theme=0"
        width="100%"
        height="152"
        loading="lazy"
        style={{ border: "none" }}
      ></iframe>
    ),
    src: people11,
    title: "Power Journey Of Diversity!",
    date: "Dec 2021",
    name: "Sjoerd Marijne",
    details: "Former Coach, Indian Women's Hockey Team",
    desc: "This podcast in the ESG Decibels Series is with the former coach of the Indian Women’s Olympics Hockey Team 2021 – Sjoerd Marijne – a dynamic personality in the global sports domain. Sjoerd speaks about his mindset when he started coaching and overcame obstacles to create a strong team. He identified the individual team members’ characteristics, nurtured them to enhance the positivities and guided them to succeed. Sjoerd encourages us to control what is possible and not fret over the uncontrollable. He is a true inspiration to millions across the globe. His contribution to the Indian Hockey is immense. Take for instance the Tokyo Olympics, where India women’s hockey team could not win medal but won a billion hearts. he is proud of his team and that they have inspired a million girls back home despite not finishing their stunning campaign with a medal.",
  },
  {
    embeded: (
      <iframe
        src="https://open.spotify.com/embed/episode/7q5xehDPHk1FJYyfEvo5l9?utm_source=generator&theme=0"
        width="100%"
        height="152"
        loading="lazy"
        style={{ border: "none" }}
      ></iframe>
    ),
    src: people12,
    title: "ESG & INVESTING",
    date: "Nov 2021",
    name: "Ameya Prabhu",
    details: "Managing Director NAFA Capital",
    desc: "This podcast captures a lively and riveting discussion between Sonal Verma – Partner – ESG & Global Leader-Markets & Strategies, Dhir & Dhir Associates and a dynamic entrepreneur – Ameya Prabhu who is the Managing Director of NAFA Capital and recognised as a young leader by World Economic Forum. The discussion starts with Sonal setting the tone about ESG and its importance with the recent COP26 summit. Ameya speaks about the importance of ESG, initially explaining very briefly about the impact of business on Environment and then weaving in relevant aspects of significance. The discussion progresses to highlight ‘ESG & investing’, where Ameya speaks about very pertinent facets of ESG and investors’ mindsets. The last discussion point revolves around the regulatory aspect of ESG. The entire podcast highlighted the importance of ESG and has piqued our interest in waiting for more insightful sessions in this series.",
  },
];
