import React from "react";
import { Box, Typography } from "@mui/material";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";

function Info() {
  return (
    <Box className="info-div" data-aos="fade-up">
      <Box className="first-div">
        <Typography variant="h5">
          Statutory & Regulatory Compliance Framework
        </Typography>
        <br />
        {data1.map((item, index) => (
          <Typography variant="subtitle1" className="subtitle-text">
            <FaCheck /> &nbsp; {item.text1}
          </Typography>
        ))}
        <br />
        <Link to="/contact-us" className="common-button-opposite">
          Know More
        </Link>
      </Box>
      <Box className="second-div">
        <Typography variant="h5">
          ESG Framework Platform (Recommended)
        </Typography>
        <br />
        {data2.map((item, index) => (
          <Typography key={index} variant="h6" className="subtitle-text">
            <FaCheck /> &nbsp; {item.text1}
          </Typography>
        ))}
        <br />
        <Link to="/contact-us" className="common-button-opposite">
          Know More
        </Link>
      </Box>
    </Box>
  );
}

export default Info;

const data1 = [
  {
    text1: "All tech features",
  },
  {
    text1: "No limit on number of users",
  },
  {
    text1: "No limit on number of company locations in 1 country",
  },
  {
    text1:
      "Includes 10 hours of experts for DIFM to Customise compliance repository on location/industry requirements",
  },
];
const data2 = [
  {
    text1: "Includes BBBE Framework",
  },
  {
    text1: "Customised Compliance Repository on Location/Industry Requirements",
  },
  {
    text1: "All tech features",
  },
  {
    text1: "No limit on number of users",
  },
  {
    text1: "No limit on number of company locations in 1 country",
  },
  {
    text1: "Includes 10 hours of experts for DIFM",
  },
];
