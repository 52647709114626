import { Box, Typography } from "@mui/material";
import React from "react";

function Banner() {
  return (
    <>
      <Box
        className="esg-framework-banner esg-framework-banner-2"
        data-aos="fade-up"
      >
        <Box className="overlay-bg-esg" />
        <Box className="grey-bg-with-text">
          <Box
            className="text-wrapper"
            data-aos="zoom-out"
            data-aos-delay="600"
          >
            <Typography variant="h5">An ESG Solution for the</Typography>
            <br />
            <Typography variant="h1">New Business Age</Typography>
            <br />
            <Typography variant="subtitle3" color="rgba(255, 255, 255, 0.74)">
              Heat Maps on ESG elements gives the management tangible
              decision-making information e.g., resource allocation, CAPEX,
              OPEX, underperforming ESG Elements etc. And much more.
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Banner;
