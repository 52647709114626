import { createTheme } from "@mui/material/styles";
import "./index.scss";

const defaultTheme = createTheme({
  palette: {
    background: {
      default: "#FFFFFF",
    },
    primary: {
      main: "#ffffff",
      dark: "#000000",
    },
    secondary: {
      main: "#ffffff",
      light: "#000000",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: "CircularStd, Karla",
  },
});

const theme = {
  ...defaultTheme,
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: "CircularStd",
          fontSize: "54px",
          lineHeight: "60px",
          [defaultTheme.breakpoints.down(900)]: {
            fontSize: "20px",
            lineHeight: "30px",
          },
        },
        h2: {
          fontFamily: "CircularStd",
          fontSize: "45px",
          lineHeight: "54px",
          color: "#000",
          [defaultTheme.breakpoints.down(900)]: {
            fontSize: "25px",
            lineHeight: "30px",
          },
        },
        h3: {
          fontFamily: "CircularStd",
          fontSize: "30px",
          lineHeight: "39px",
          color: "#000000",
          [defaultTheme.breakpoints.down(900)]: {
            fontSize: "20px",
            lineHeight: "25px",
          },
        },
        h4: {
          fontFamily: "CircularStd",
          fontSize: "34px",
          lineHeight: "50px",
          color: "#000000",
          [defaultTheme.breakpoints.down(900)]: {
            fontSize: "20px",
            lineHeight: "25px",
          },
        },
        h5: {
          fontFamily: "CircularStd",
          fontSize: "22px",
          lineHeight: "26px",
          [defaultTheme.breakpoints.down(900)]: {
            fontSize: "15px",
            lineHeight: "20px",
          },
        },
        h6: {
          fontFamily: "CircularStd",
          fontSize: "16px",
          lineHeight: "26px",
          [defaultTheme.breakpoints.down(900)]: {
            fontSize: "13px",
            lineHeight: "20px",
          },
        },
        subtitle1: {
          fontFamily: "CircularStd",
          fontSize: "15px",
          lineHeight: "30px",
          // color: "black",
          [defaultTheme.breakpoints.down(900)]: {
            fontSize: "14px",
            lineHeight: "20px",
          },
        },
        subtitle2: {
          fontFamily: "Karla",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "24px",
          lineHeight: "35px",
          color: "black",
          [defaultTheme.breakpoints.down(900)]: {
            fontSize: "14px",
            lineHeight: "20px",
          },
        },
        subtitle3: {
          fontFamily: "Karla",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "20px",
          lineHeight: "30px",
          color: "black",
          [defaultTheme.breakpoints.down(900)]: {
            fontSize: "14px",
            lineHeight: "20px",
          },
        },
        subtitle4: {
          fontFamily: "Karla",
          fontWeight: 700,
          fontSize: "25px",
          lineHeight: "30px",
          [defaultTheme.breakpoints.down(900)]: {
            fontSize: "14px",
            lineHeight: "20px",
          },
        },
        subtitle5: {
          fontFamily: "Karla",
          fontWeight: 600,
          fontSize: "12px",
          lineHeight: "20px",
        },
        body1: {
          fontFamily: "Karla",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "28px",
          [defaultTheme.breakpoints.down(900)]: {
            fontSize: "14px",
            lineHeight: "20px",
          },
        },

        body2: {
          fontFamily: "CircularStd",
          fontSize: "18px",
          lineHeight: "28px",
          color: "black",
          [defaultTheme.breakpoints.down(900)]: {
            fontSize: "14px",
            lineHeight: "20px",
          },
        },
        body3: {
          fontFamily: "CircularStd",
          fontSize: "16px",
          lineHeight: "28px",
          [defaultTheme.breakpoints.down(900)]: {
            fontSize: "14px",
            lineHeight: "20px",
          },
        },
        body4: {
          fontFamily: "CircularStdMd",
          fontSize: "16px",
          lineHeight: "28px",
          [defaultTheme.breakpoints.down(900)]: {
            fontSize: "14px",
            lineHeight: "20px",
          },
        },
      },
    },
  },
};

export default theme;
