import { Box, Typography } from "@mui/material";
import React from "react";
import DD from "../../img/D&D.png";

function KPartners() {
  return (
    <Box className="kpartners-wrapper ">
      <Box className="grey-bg">
        <Typography variant="h3">Our Knowledge Partner</Typography>
        <br />
        <br />
        <Typography variant="subtitle4" className="dd-text">
          Dhir & Dhir Associates, One of the leading law firm in India, is our
          exclusive knowledge partner. Through enriching legal and regulatory
          expertise, it empowers us to meet all statutory advancement
          compliance-driven aspects of this cutting-edge technological
          advancement.
        </Typography>
        <Box className="ddimg">
          <img src={DD} alt="" />
        </Box>
      </Box>
    </Box>
  );
}

export default KPartners;
